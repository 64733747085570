import { Row, Col, Input, Space } from "antd";
import React, { ReactNode, memo } from "react";
import classname from "classnames";
import "./index.scss";
import { SearchProps } from "antd/es/input";
import { useDebounceFn } from "ahooks";
import { useCompositionInput } from "@src/hooks/useCompositionInput";
const defaultProps = {};
type props = {
  title?: ReactNode;
  action?: ReactNode;
  search?: {
    onSearch?: (value: string) => void;
  }&Omit<SearchProps,'onChange'|'onSearch'>;
};
export type TableToolBarProps = Required<typeof defaultProps> & props;
export const TableToolBar: React.FC<TableToolBarProps> = memo((props) => {
  const { title, action, search } = props;
  const debouncedSearch = useDebounceFn((value: string)=>{
    search?.onSearch?.(value.trim());
  },{wait:600})
  const onSearch = (value: string) => {
    debouncedSearch.run(value)
  };
  const compositionInputProps = useCompositionInput((e)=>{
    onSearch(e.target.value);
  })
  return (
    <Row justify={"space-between"} align={'middle'} className="xz-table-toolbar-container">
      <Col>
        <div className={classname("xz-table-toolbar-title")}>{title}</div>
      </Col>
      <Col>
        <Space>
          {search?<Input.Search
            {...search}
            style={{ minWidth: (search.placeholder?.length||10) * 16 + 80 ,...search.style }}
            {...compositionInputProps}
            onSearch={(e) => {
              onSearch(e);
            }}
          ></Input.Search>:<></>}
          {action}
        </Space>
      </Col>
    </Row>
  );
});
TableToolBar.defaultProps = defaultProps;
