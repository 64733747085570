import { Button, Descriptions, Space } from "antd";
import React, { memo } from "react";
import { OrderDetailContext, useOrderDetail } from "./useOrderDetail";
import { OrderBasicInfo } from "./basicInfo";
import { OrderContractInfo } from "./contractInfo";
import { OrderPaymentInfo } from "./PaymentInfo";
import { LogisticsInfo } from "./logisticsInfo";
import { OrderInvoiceInfo } from "./InvoiceInfo";
import './index.scss'
import { dateWithFallback } from "@/utils/utils";
import { ContractStatusEnum, OrderPaymentStatusEnum } from "@/constant/order";
import { DetailPageContent } from "@src/components/DetailPageComponents/Content";
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header";
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer";
import { TagCustomStyle } from "@src/components/TagCustomStyle";

const defaultProps = {};
type props = {};
const prefix = 'new-order-detail-page'
const classWithPrefix = (classname: string)=>{
  return [prefix,classname].join('-')
}
export type NewPurchaseOrderProps = props &
  React.HTMLAttributes<HTMLDivElement>;
export const NewPurchaseOrder: React.FC<
  React.PropsWithChildren<NewPurchaseOrderProps>
> = memo(() => {
  const orderDetail = useOrderDetail();
  return (
    <OrderDetailContext.Provider value={orderDetail}>
      <DetailPageContainer>
        <DetailPageHeader
          title={<Space><span>采购订单：{orderDetail.data?.id}</span> <Button onClick={()=>history.go(-1)}>返回上一级</Button></Space>}
          breadCrumb={[
            { label: "订单管理" },
            { label: "采购订单" },
            { label: "详情" },
          ]}
          rightContent={
            <Space size='large'>
            <div className={classWithPrefix('status-box')}>
              <div className={classWithPrefix('status-label')}>合同签署</div>
                <TagCustomStyle {...ContractStatusEnum[orderDetail.data.contract?.signStatus?orderDetail.data.contract?.signStatus:'PENDING']} />
            </div>
            {/* 1.0.3不做 */}
            {false && <div className={classWithPrefix('status-box')}>
              <div className={classWithPrefix('status-label')}>物流状态</div>
              <TagCustomStyle status="error" text={"未完成"} />
            </div>}
            <div className={classWithPrefix('status-box')}>
              <div className={classWithPrefix('status-label')}>支付状态</div>
              <TagCustomStyle {...OrderPaymentStatusEnum[orderDetail.data.paymentStatus?orderDetail.data.paymentStatus:'PENDING']} />
            </div>
            {/* 1.0.3不做 */}
            {false&&<div className={classWithPrefix('status-box')}>
              <div className={classWithPrefix('status-label')}>开票状态</div>
              <TagCustomStyle status="error" text={"未完成"} />
            </div>}
            </Space>
          }
        >
          <br />
          <Descriptions>
            <Descriptions.Item label="创建时间">{dateWithFallback(orderDetail.data?.createTime)?.format('YYYY-MM-DD HH:mm:ss')}</Descriptions.Item>
            <Descriptions.Item label="来源">{orderDetail.data.subChannel}</Descriptions.Item>
          </Descriptions>

        </DetailPageHeader>
        <DetailPageContent>
          <OrderBasicInfo />
          <OrderContractInfo />
          <OrderPaymentInfo />
          {/* v1.7.5 暂不开发 */}
          {false && (
            <>
              <LogisticsInfo />
              <OrderInvoiceInfo />
            </>
          )}
        </DetailPageContent>
      </DetailPageContainer>
    </OrderDetailContext.Provider>
  );
});
NewPurchaseOrder.defaultProps = defaultProps;

export default NewPurchaseOrder;
