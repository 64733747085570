import { useRequest } from "ahooks";
import Util from "../../services/util";
import { useCallback, useEffect } from "react";
import { message } from "antd";
import { Many, pick } from "lodash";
import axios from "axios";
import { getInvoiceConfiguration } from "@src/services/api/invoice/invoice";
import { cross_url_env } from "@/services/util";
const util = Util.getInstance();

export const getTaxRegions = (params?: any) => {
  return util
    .httpGet("/sinzetech-crm/corporealEntity/tax", params)
    .then((res) => res);
};

export const updateEntity = (params?: any) => {
  return util
    .httpPut(`/sinzetech-crm/corporealEntity/${params.id}`, params)
    .then((res) => res);
};
export const createEntity = (params?: any) => {
  const { ...rest } = params;
  return util
    .httpPost(`/sinzetech-crm/corporealEntity`, rest)
    .then((res) => res);
};

// 关联实体详情
export const customerRelatedEntityDetail = (id: any, value?: any) => {
  return util
    .httpGet(`/sinzetech-crm/corporealEntity/${id}`, value)
    .then((res) => res);
};

// 业务负责人数据
export const getAllUsers = (values: any) => {
  return util.httpGet("/sinzetech-admin-user/users", values);
};

// 筛选办税人数据
export const getTaxUsers = (values: any) => {
  return util.httpGet<{id:string,name:string}[]>("/sinzetech-admin-user/users/fill", values);
};

// 获取所有税区
export const getTaxs = (values: any) => {
  return util.httpGet("/sinzetech-tax/tax-region", values);
};

const tradeEntityPrefix = '/trade-entity'

// 获取实体详情
export const getEntityDetail = (id: string) => {
  return util.httpGet<API.Entity.Info>(tradeEntityPrefix+`/entity/${id}`,{
    params: {id:id}
  }).then((res)=>res.data);
};

// 更新实体详情
export const updateEntityDetail = (data: Partial<API.Entity.Info>) => {
  return util.httpPut(tradeEntityPrefix+`/entity`, data);
};

// 获取税区列表
export const getTaxList = (values:any) => {
  return util.httpGet('/sinzetech-tax/tax-region', values)
  .then(res => res?.data?.records as {id:number,name: string}[])
}

// 发票项目
export const getInvoiceItems = (values?: any) => {
  return axios.get(getInvoiceConfiguration, {params:values})
  .then(res => {
    return res.data.data.records as API.Invoice.ItemConfiguration[]
  } )
}

// 交易列表
interface Entry {
  current: string | number,
  size: string | number,
  level?: string | null,
  sourceType?: string|null,
  status?: string| null,
  businessLeaderId?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  regionId?: string | null,
  keyword?: string | number,
  type?: string | null,
  taxpayerId?:string | null
}
export const getEntryList = (params: Entry) => {
  return util.httpGet('/trade-entity/entity', params)
}

// 新增
export const addEntry = (params: any) => {
  return util.httpPost('/trade-entity/entity', params)
}

// ocr 识别  根据上传营业执照识别关键字段信息
export const getImageMsg = (values?: any) => {
  return util
    .httpGet("/sinzetech-ocr/ocr/business-license", values)
    .then((res) => res);
};
// 获取客户
export const getCustomerInfo = (id?: any) => {
  return util.httpGet(`/sinzetech-crm/customer/${id}`)
    .then(res => res)
}
// // 获取税区列表
// export const getTaxList = (values:any) => {
//   return util.httpGet('/sinzetech-tax/tax-region', values)
//   .then(res => res)
// }

// 税收分类
export const getCategorySgort = () => {
  return util.httpGet('/pub-data/invoice-configuration/invoice-category/short', {}, true, cross_url_env)
}

// 发票项目数据
interface Cate{
  size: string | number,
  current: string | number,
  invoiceCategoryShort?: string,
  keyword?: string
}
export const getCategoryTaxs = (params: Cate) => {
  return util.httpGet('/pub-data/invoice-configuration', params, true, cross_url_env)
}

// 修改业务负责人
interface EDITBUSINEESS{
  userId?: number,
  userName?: string,
  ids?:number[]
}
// 修改业务负责人
export const editBusinessUser = (params: EDITBUSINEESS) => {
  return util.httpPut('/trade-entity/entity/businessLeaderName', params)
}
// 修改办税人
export const editTaxUser = (params: EDITBUSINEESS) => {
  return util.httpPut('/trade-entity/entity/taxpayer', params)
}

// 获取业务负责人或者办税人
export const getBusinessAndTax = (type: string) => {
  return util.httpGet(`/trade-entity/entity/users/${type}`)
}

// 根据文件id获取，文件链接
export const getUrlByFileId = (params:{zoom:boolean,fileIds:string}) => {
  return util.httpGetDomain(`/admin/sinzetech-resource/files/ossUrlZoom`, params)
  .then(res => res as {data:string[],code:number}) 
}

// 获取用户组数据
export const getUsersGroup = (params:any) => {
  return util.httpGet('/sinzetech-admin-user/user-groups', params)
}

// 获取用户组详情
export const getUserInfo = (id: string) => {
  return util.httpGet(`/sinzetech-admin-user/user-groups/${id}`)
}

// 采购订单
interface PRODUCTOR{
  size: string | number,
  current: string | number,
  keyword?: string,
  startTime?: string,
  endTime?: string,
  timeType?: string,
  keywordType?: string
  orderTypes?: string,
  orderInvoiceStatus?: [string]
  contractStatus?: [string]
  platCatId: [string]
  taxRegionIds: [string]
  buyerIds: [string]
  sellerIds: [string]
}
export const productOrders = (params: PRODUCTOR) => {
  return util.httpGet('/sk-trade/orders', params)
}

// 关闭订单
export const closePructor = (id: string) => {
  return util.httpPutUrl(`/sk-trade/orders/close/${id}`)
}

// 品类数 
interface SEARCHCATEGORY{
  keyword?:string | null
}
export const getCategoryTree = (params?: SEARCHCATEGORY) => {
  return util.httpGet('/sk-trade/categories/tree/search', params)
}

// 创建品类
interface ADDCATEGORY{
  name?: string | undefined,
  ADDCATEGORY?: string | number
  descText?: string
  descPhoto?: string,
  forTrade?: string | boolean,
  forPriceIndex?:string | boolean
}
export const addCategoryTree = (params: ADDCATEGORY) => {
  return util.httpPost('/sk-trade/categories', params)
}

// 删除品类
export const deleteCategory = (id: string) => {
  return util.httpDeletel(`/sk-trade/categories/${id}`)
}

// 编辑品类
export const editCategory = (id: string, params: ADDCATEGORY) => {
  return util.httpPut(`/sk-trade/categories/${id}`, params)
}
