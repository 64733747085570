// @ts-nocheck
// import { LoadingLG } from '@components/loading/loding'
import { proxyAxios } from '@src/services/axiosRequest'
import { useSetState } from 'ahooks'
import { Checkbox, message, Modal, Radio } from 'antd'
import Axios from 'axios'
import React, { useEffect } from 'react'
import * as XLSX from 'xlsx'

export interface BaseHooksProps {
  cancelModal?: (refresh?: boolean) => void;
  Power?: { [key: string]: boolean };
  // Status?: StatusState;
  id?: string;
  ids?: string[];
}

interface ExportListProps extends BaseHooksProps {
    columnsKey: () => any[],
    serchObj: generic | (() => generic),
    size: number,
    current: number,
    total: number,
    url: string,
    pageKey?: string
    headers?: { [key: string]: any }
    parentDownload?: (header, fields, result: any[]) => void
    exportName?: string
    // paramsTransform?: (any)=>any
    // resultTransform?: (any)=>any
}

interface State {
    isLoading: boolean
    exportType: 1 | 2
    columnsKey: any[],
    selectList: any[],
    isAllChecked: boolean,
}

const ExportList = ({ cancelModal, columnsKey, parentDownload, exportName, ...props }: ExportListProps) => {
    let [state, setState] = useSetState<State>({
        isLoading: false,
        exportType: 2,
        columnsKey: [],
        selectList: [],
        isAllChecked: true,
    })
    useEffect(() => {
      const auth = JSON.parse(window.localStorage.getItem('operation_authorization')||"{}")

        let settings: { [key: string]: any } | null = JSON.parse(localStorage.getItem(`${auth?.userId}_PageExportSetting`)||"{}")
        let initColumnsKey = []
        columnsKey().forEach((item) => {
            if (item.dataIndex != 'operation') {
                initColumnsKey.push({
                    valueEnum: item.valueEnum,
                    label: item.title,
                    value: item.dataIndex,
                })
            }
        })
        let pageKey = props?.pageKey || location.pathname
        let initSelectList = settings?.[pageKey] || initColumnsKey.map(item => item.value)
        setState({
            columnsKey: initColumnsKey,
            selectList: initSelectList,
            isAllChecked: initSelectList.length === initColumnsKey.length
        })
    }, [])
    const submit = () => {
        let { selectList, columnsKey } = state;
        console.log(props,selectList,columnsKey, '0000')
        let list = [];
        selectList.forEach((item,) => {
            columnsKey.forEach((it, ix) => {
                if (item == it.value) {
                    list.push(it)
                }
            })
        })
        if (list.length == 0) {
            message.error('至少选择一列导出！')
            return
        }
        let fields = {}, newKey = [];
        list.forEach((item,) => {
            fields[item.value] = item.label;
            newKey.push(item.value)
        })
        const auth = JSON.parse(window.localStorage.getItem('operation_authorization')||"{}")
        let settings: { [key: string]: any } | null = JSON.parse(localStorage.getItem(`${auth?.userId}_PageExportSetting`) || "{}")
        let pageKey = props?.pageKey || location.pathname
        settings[pageKey] = newKey

        localStorage.setItem(`${auth?.userId}_PageExportSetting`, JSON.stringify(settings))
        
        proxyAxios.get(props.url, {
            params: {
                ...props.serchObj,
                // size: state.exportType == 1 ? props.size : props.total,
                // current: state.exportType == 1 ? props.current : 1,
                pageSize: state.exportType == 1 ? props.size : props.total,
                pageNumber: state.exportType == 1 ? props.current : 1
            },
            // headers: {
            //     // isExport: true,
            //     fields: encodeURI(JSON.stringify(fields)),
            //     ...props.headers,
            // },
        }).then(res => {
            let header: Array<string> = list.map(item => item.value)
            if (parentDownload) {
                parentDownload(header, fields, res.data.data.records)
                return
            }
            console.log(fields, 'header')
            console.log(res.list, 'res.data.data.records')
            const workbook = XLSX.utils.book_new();
            console.log(list,'----')
            const map = list.reduce((obj,item)=>({...obj,[item.value]:item}),{})
            console.log(map,'----')
            const ws = XLSX.utils.json_to_sheet([fields, ...res.list.map(item => {
                let data = {}
                header.forEach((it, index) => {
                    data[it] =  map[it].valueEnum? map[it].valueEnum[item[it]]:item[it]
                })
                return data
            })], {
                header,
                skipHeader: true
            });
            XLSX.utils.book_append_sheet(workbook, ws, "Sheet1");
            XLSX.writeFile(workbook, exportName || "导出数据.xlsx", { bookType: "xlsx" });
            cancelModal()
        }).catch(err => {
            setState({ isLoading: false, })
        })
    }

    // 单个选择
    const childrenChange = (list: any[]) => {
        let { columnsKey, isAllChecked } = state;
        columnsKey.length === list.length ? isAllChecked = true : isAllChecked = false;
        setState({
            selectList: list,
            isAllChecked,
        })
    }
    // 全选/取消全选
    const onCheckAllChange = (e: { target: { checked: any; }; }) => {
        let { columnsKey, selectList } = state;
        e.target.checked ? selectList = columnsKey.map(item => item.value) : selectList = []
        setState({ isAllChecked: e.target.checked, selectList })
    }
    return (
        <div id='inStorage_modal'>
            {
                state.isLoading ? <></> : null
            }
            <Modal
                className='export_inStorage_modal'
                title='数据导出'
                visible={true}
                onOk={submit}
                onCancel={() => {
                    cancelModal()
                }}
                cancelText='取消'
                okText='确定'
            >
                <div className='exportType'>
                    <Radio.Group
                        name='radiogroup'
                        value={state.exportType}
                        onChange={(e) => {
                            setState({ exportType: e.target.value })
                        }}
                    >
                        <Radio value={1}>导出当前查询分页数据</Radio>
                        <Radio value={2}>导出当前查询全部数据</Radio>
                    </Radio.Group>
                </div>
                <div className='CheckAll' style={{ borderBottom: '1px solid #e9e9e9', marginBottom: 8, paddingBottom: 2, }}>
                    <Checkbox
                        onChange={(e) => { onCheckAllChange(e) }}
                        checked={state.isAllChecked}
                    >
                        导出表头选择
                    </Checkbox>
                </div>
                <Checkbox.Group
                    options={state.columnsKey}
                    value={state.selectList}
                    onChange={(list) => { childrenChange(list) }}
                />
            </Modal>
        </div>
    )
}

export default ExportList