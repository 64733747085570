import React,{useEffect, useState, useRef} from 'react'
import BreadCrumbBar from '../../../../../../components/BreadCrumbBar'
import { Table, Form, Input, Button, Radio, Select, message, Tooltip } from 'antd'
import { QuestionOutlined} from '@ant-design/icons'
import './index.scss'
import {getFieldList, addTemplateFiled, associatedFields} from '../../Promise'
import { useLocation, useNavigate } from 'react-router-dom'

const Option = Select.Option
const layout ={
  label:{
    span: 5
  },
  wrapperCol:{
    span: 16
  }
}
const selectOptions =[
  {
      label:'图片',
      value:'photo',
    },{
      label:'文件',
      value:'file',
    },{
      label:'视频',
      value:'video',
  
    },{
      label:'音频',
      value:'audio',
      step: 3,
      type: 'file'
  
    },{
      label:'文本',
      value:'text',
      step: 3,
      type: 'text'
  
    },{
      label:'时间',
      value:'time',
      step: 3,
      type: 'date'
  
    },{
      label:'日期',
      value:'date',
      step: 3,
      type: 'date'
  
    },{
      label:'下拉选项',
      value:'select',
      step: 2,
      type: 'select'
    },
    {
      label:'金额',
      value:'money',
      step: 3,
      type: 'number'
    },{
      label:'重量',
      value:'weight',
      step: 3,
      type: 'number'
  
    },{
      label:'手机号',
      value:'phone',
      step: 3,
      type: 'text'
  
    },{
      label:'身份证号',
      value:'id_num',
      step: 3,
      type: 'text'
  
    },{
      label:'车牌',
      value:'vehicle',
      step: 3,
      type: 'text'
  
    },{
      label:'邮箱',
      value:'email',
      step: 3,
      type: 'text'
    },{
      label:'坐标',
      value:'coordinate',
      step: 2,
      type: 'number'
    }
]

const breadsList =[
  {
      label:'存证平台',
      path: '/evidence'
  },
  {
      path: '/evidence/platform',
      label:'平台配置'
  },
  {
      path:'/evidence/platform/field',
      label:'存证场景模板'
  },
  {
    path:'',
    label:'配置字段'
  }
]

const dataSourceSortOptions:any = [{
  value:'小程序录入',
  label:'小程序录入'
},
{
  value:'电脑端手动上传',
  label:'电脑端手动上传'
},{
  value:'地磅采集',
  label:'地磅采集'
},{
  value:'现场摄像头采集',
  label:'现场摄像头采集'
},{
  value:'系统对接',
  label:'系统对接'
}]

const uuid = ()=> {
  let date=(new Date()).valueOf();//获取时间戳
  let txt = '1234567890';//生成的随机机器码
  let len =13;//机器码有多少位
  let pwd = '';//定义空变量用来接收机器码
  for (let i = 0; i < len; i++) {
    pwd += txt.charAt(Math.floor(Math.random() * txt.length));//循环机器码位数随机填充
  }
  return date+pwd;
}

const FieldList = () => {
  const navigate = useNavigate()
  const [messageApi, contextHolder] = message.useMessage()
  const location = useLocation()
  const [form] = Form.useForm()
  // 客户详情-模板详情-字段详情标记
  const [isManageInfo, setDisable] = useState(false)
  // const [ setScrollY] = useState<number>(0);
  const tableRef = useRef<null | HTMLDivElement>(null);
  const [list, setList] = useState<any>([])
  const [allField, setAllField] = useState<any>([])
  const [fields, setfields] = useState<any>([])
  const [pageOptions] = useState<any>({
    pageSize:9999999,
    current:1,
    total: 0
  })
  // 获取字段列表
  const getFiles = () => {
    const params ={
      current:1,
      size: 999999
    }
    getFieldList(params)
    .then(res => {
      if(res.successful){
        setfields(res?.data?.records)
        setAllField(res?.data?.records)
        if(location.state?.secureEvidenceTemplateLinkId){
          getAssociation(res?.data?.records)
        }
      }
    })
  }
  // 获取已关联字段列表详情
  const getAssociation = (list:any) => {
    if(!location?.state?.secureEvidenceTemplateLinkId  || location?.state?.secureEvidenceTemplateLinkId === '') return 
    associatedFields(location?.state?.secureEvidenceTemplateLinkId)
    .then((res:any) => {
      if(res?.successful){

        
        const arr = res?.data || []
        let newList:any =[]
        arr.forEach((item:any) => {
          let obj = {
            ...item,
            id:item.fieldId
          }
          newList.push(obj)
        });
        setList(newList)
        const newArr = list.filter ((item:any) => {
          if(arr.length === 0) return item
          return arr.find((ele:any) => ele.id !== item.id)
        })
        setfields(newArr)
      }
    })
  }
  useEffect(() => {
    getFiles()
    const type = location?.state?.type
    if(type === 'manage'){
      setDisable(true)
    }
    if(type === 'add'){
      setDisable(false)
    }
    // if(type === 'edit' && !location?.state?.isHandleEdit){
    //   setDisable(false)
    // }
    // if(type === 'edit' && location?.state?.isHandleEdit){
    //   setDisable(true)

    // }
    console.log(location, '////////////////////////')
  }, [location]) // eslint-disable-line
  const handleCreate = () => {
    const res = JSON.parse(JSON.stringify(list))
    const defaultObject={
      name:'',
      type:'',
      isRequire: 0, 
      id: uuid(),
      dataSourceSort:'',
      componentType:''
    }
    res.push(defaultObject)
    setList(res)
  }
  const handleSelect= (e:any, record:any) => {
    const filedValue = JSON.parse(e)
    const res = JSON.parse(JSON.stringify(list))
    const newList = res.map((item:any) => {
      if(item.id === record?.id){
        item.type= filedValue.type
        item.name = filedValue.name
        item.id = filedValue.id,
        item.isRequire = filedValue.isRequire,
        item.componentType = filedValue.componentType
      }
      return item
    })
    const newFields = fields.filter((item:any) => {
      return item.id !== filedValue.id
    })
    setfields(newFields)
    setList(newList)
  }
  const columns =[
    {
      title:'字段名称',
      // dataIndex:'name',
      width:300,
      render: (record:any) => {
        return <div>
          {
            record?.name !== ''? record.name : <Select style={{width:'200px'}} onChange={(e:any) => 
            handleSelect(e, record)}>
            {
              fields.map((item:any,index:any) => {
                return <Option key={index} value={JSON.stringify(item)}>{item.name}</Option>
              })
            }
          </Select> 
          }
          
        </div>
      }
    },
    {
      title:'字段类型',
      dataIndex:'componentType',
      render:(record:any) => {
        console.log(record,'===')
        const str = record.toLowerCase()
        const res = selectOptions.find((item:any) => item.value === str)
        return <span>{res?.label}</span>
      }
    },{
      title:'字段限制',
      // dataIndex:'xianzhi'
      render: (record:any) => {
        return <Button type='link' onClick={() => {
          navigate('/evidence/platform/field/add', {
            state:{
              id: record.id,
              isInfo:true
            }
          })
          }
        }>详情</Button>
      }
    },{
      title:'是否必填',
      render: (record:any) => {
        return <div>
          <Radio.Group disabled={location?.state?.type !=='add' } defaultValue={record.isRequire || 0} onChange={(e:any) => handleRadio(e.target.value, record)}>
            {
              [{label:'是', value:1}, {label:'否', value: 0}].map((item:any) => <Radio value={item.value} key={item.value}>{item.label}</Radio>)
            }
          </Radio.Group>
        </div>
      }
    },
    {
      title:'字段数据录入源',
      // dataIndex:'old',
      width:'600',
      render: (record:any) => {
        const value = record?.dataSourceSort
        return <div>
          <Select disabled={location?.state?.type !=='add'} defaultValue={value? value.split(','): []} size='small' mode="multiple" style={{width: '220px',}} options={dataSourceSortOptions} onChange={(e) => handleMultiple(e, record)}></Select>
          <Tooltip title="按照选择顺序确定优先级；不选代表不使用">
            <Button style={{margin:'0 10px'}} size='small' type="primary" shape="circle" icon={<QuestionOutlined />} />
          </Tooltip>
          <span>{record?.dataSourceSort}</span>
        </div>
      }
    }
  ]
  const handleMultiple= (e:any, record:any) => {
    const str =e ? e.toString(): ''
    const newList = JSON.parse(JSON.stringify(list))
    const res = newList.map((item:any) => {
      if(item.id === record?.id){
        item.dataSourceSort = str
      }
      return item
    })
    setList(res)
  }
  const handleRadio = (e:any, record:any) => {
    const newList = JSON.parse(JSON.stringify(list))
    const res = newList.map((item:any) => {
      if(item.id === record?.id){
        item.isRequire = e
      }
      return item
    })
    setList(res)
  }
  const onFinsh = () => {
    let arr:any =[]
    list.forEach((item:any) => {
      if(item.name && item.name !== ''){
        let obj ={
          fieldId: item.id,
          isRequire: item.isRequire === undefined? 0 : item.isRequire, 
          dataSourceSort: item.dataSourceSort
        }
        arr.push(obj)
      }
    })

    const params ={
      fieldList: arr,
      secureEvidenceTemplateLinkId: location?.state?.secureEvidenceTemplateLinkId
    }
    addTemplateFiled(params)
    .then((res:any) => {
      if(res?.successful){
        messageApi.open({
          type:'success',
          content:'关联成功',
          onClose:() => {
            navigate('/evidence/platform/template/add', {
              state:{
                id: location.state?.id,
                current: 1,
                type:location?.state?.type ,
                info:false,
                isHandleEdit:location?.state?.isHandleEdit 
              }
            })
          }
        })

      }else{
        messageApi.open({
          type:'error',
          content:res?.message||'关联字段保存失败'
        })
      }
    })
  }
  return <div className='container'>
     {contextHolder}
    <div className='content'>
      <BreadCrumbBar breads={breadsList}></BreadCrumbBar>
      <div>
        <Form {...layout} form={form}>
          <Form.Item label='阶段'>
            <Input style={{width: '200px'}} disabled value={location?.state?.templateName}></Input>
            <span>配置字段</span>
          </Form.Item>
        </Form>
        {
          location?.state?.type ==='add' ?<div className='right'>
           <Button onClick={() => {
              setList([])
              setfields(allField)
           }}>重置</Button>
           <Button type='primary' onClick={() => onFinsh()}>保存</Button>
        </div>: <div className='right'>
          <Button onClick={() => {
          navigate(-1)
        }}>返回上一级</Button>
        </div>   
        }
        
        <p>选择阶段字段</p>
        <div style={{minWidth: '1000px', overflow:'auto', maxHeight:'600px'}}>
            <Table  style={{minHeight:'100px'}} pagination={pageOptions} columns={columns} dataSource={list}  ref={tableRef} ></Table>
            
          
        </div>
        {
          location?.state?.type ==='add' ?<Button type='primary' onClick={() =>  handleCreate()}>新增字段</Button> : null
        }
        
      </div>
    </div>
  </div>
}

export default FieldList