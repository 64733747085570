import { useEffect, useState } from "react";
import { Button, Row, Col, Input, Form, message } from "antd";
import BreadCrumbBar from "@src/components/BreadCrumbBar";
import TableCom from "@src/components/TableCom";
import SearchForm from "@src/components/FormItem";
import styles from "../../index.module.scss";
import { getCompanyList, addCompanyApi } from "../promise";
import ModalCom from "@/components/ModalCom";
import "./index.scss";
import { useForm } from "antd/es/form/Form";
const breads = [
  {
    label: "供应链金融",
    path: "/supply",
  },
  {
    label: "核心企业准入",
    path: "",
  },
];
const EnterpriseAccess = () => {
  const [pageOptions, setPageOptions] = useState({
    pageSize: 20,
    current: 1,
  });
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState<any>("");
  const [list, setList] = useState([]);
  const [flag, setFlag] = useState(false);
  const [form] = useForm();
  const getList = async (object?: any) => {
    const params = {
      size: pageOptions.pageSize,
      current: pageOptions.current,
      ...{ name: search },
      ...object,
    };
    if (object?.current && object?.size) {
      setPageOptions({
        current: object?.current,
        pageSize: object?.size,
      });
    }
    const res = await getCompanyList(params);
    if (res?.code === 200) {
      setList(res?.data?.records);
      setTotal(res?.data?.total);
    }
  };
  const onSearch = (values: any) => {
    setSearch(values.name);
    getList({
      current: pageOptions.current,
      size: pageOptions.pageSize,
      name: values.name,
    });
  };
  const formColumns = [
    {
      label: "",
      dataIndex: "name",
      type: "Input",
      params: {
        placeholder: "请输入企业名称",
        style: {
          width: "200px",
        },
      },
    },
  ];
  const columns = [
    {
      title: "企业名称",
      dataIndex: "name",
    },
    {
      title: "可用融资产品",
      render: (record: any) => {
        return (
          <>
            {record?.productList?.length ? (
              record.productList.map((item: any) => {
                return <span style={{ paddingRight: "4px" }}>{item.name}</span>;
              })
            ) : (
              <span>---</span>
            )}
          </>
        );
      },
    },
  ];
  const addCompany = async () => {
    try {
      await form.validateFields();
      const res: any = await addCompanyApi({
        name: form.getFieldValue("name"),
      });
      if (res.code === 200) {
        message.info(res.message);
        getList();
        setFlag(false);
        form.resetFields();
      } else {
        message.error(res.message);
      }
    } catch (e) {}
  };
  const handleChange = (params?: any) => {
    setPageOptions({
      current: params.current,
      pageSize: params.pageSize,
    });
    getList({
      current: params.current,
      pageSize: params.pageSize,
    });
  };
  useEffect(() => {
    getList();
  }, [pageOptions.current, pageOptions.pageSize]);
  return (
    <div className={styles.supply_container}>
      <ModalCom
        title="添加核心企业"
        okText="确定"
        open={flag}
        onCancel={() => setFlag(false)}
        onOk={addCompany}
      >
        <Row>
          <Col>
            <Form form={form}>
              <Form.Item
                name="name"
                label="企业名称"
                rules={[{ required: true, message: "请输入创建的企业名称" }]}
              >
                <Input placeholder="请输入"></Input>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </ModalCom>
      <BreadCrumbBar breads={breads}></BreadCrumbBar>
      <div className={styles.supply_search}>
        <SearchForm formParams={formColumns} onSearch={onSearch}></SearchForm>
      </div>
      <div className={styles.supply_tables}>
        <div className="enterprise_access_add">
          <Button
            type="primary"
            onClick={() => {
              setFlag(true);
              form.resetFields();
            }}
          >
            添加核心企业
          </Button>
        </div>
        <TableCom
          rowKey={(record: any) => record?.id}
          dataSource={list}
          columns={columns}
          pagination={{
            ...pageOptions,
            total,
          }}
          onChange={handleChange}
          scroll={{ y: "calc(86vh - 300px)" }}
        ></TableCom>
      </div>
    </div>
  );
};

export default EnterpriseAccess;
