import React, {
  cloneElement,
  HTMLAttributes,
  PropsWithChildren,
  useState,
  MouseEvent,
} from 'react';
import { Form } from 'antd';
import { Button, Modal } from 'antd';
import { FormProps } from 'antd/es/form';
import { ModalProps } from 'antd/es/modal';
import { useLoading } from '@src/hooks/useLoading';

const defaultProps = {
  trigger: <Button>使用 trigger 参数覆盖打开Modal Form按钮</Button>,
};

interface Props<T> {
  fieldProps?: FormProps;
  modalProps?: ModalProps;
  trigger?: JSX.Element;
  onFinish?: (formData: T) => Promise<void>;
}

type ModalFormProps<T> = Partial<typeof defaultProps> & Props<T> & HTMLAttributes<any>;

function ModalForm<T>(props: PropsWithChildren<ModalFormProps<T>>) {
  const { trigger, fieldProps = {}, onFinish, children, modalProps } = {
    ...defaultProps,
    ...props,
  };
  const { form } = fieldProps;
  const [visible, setVisible] = useState(false);
  const triggerProps = {
    // htmlType:'submit',
    ...trigger?.props,
    onClick: (e: MouseEvent<HTMLElement, MouseEvent>) => {
      setVisible(true);
      trigger?.props.onClick?.(e);
    },
  };

  const [onOk,loading] = useLoading(async () => {
    await form?.validateFields();
    await onFinish?.(form?.getFieldsValue() as T);
    form?.resetFields()
    setVisible(false);
  });

  return (
    <>
      {cloneElement(trigger, triggerProps)}
      <Modal
        okButtonProps={{ htmlType: 'submit', loading }}
        okText={'提交'}
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={onOk}
        {...modalProps}
      >
        <Form {...fieldProps} form={fieldProps?.form}>
          {children}
        </Form>
      </Modal>
    </>
  );
}

export default ModalForm;
