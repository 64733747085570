import React, { useEffect, useState, useMemo, useRef } from "react";
import {
    Button,
    Row,
    Col,
    Form,
    Input,
    Select,
    Table,
    Modal,
    message,
} from "antd";
import BreadCrumbBar from "@src/components/BreadCrumbBar";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import styles from "./index.module.scss";
import {
    addEntry,
    getImageMsg,
    getCustomerInfo,
    getTaxList,
    getCategorySgort,
    getCategoryTaxs,
    getEntryList,
} from "../promises";
import UploadCom from "@src/components/UploadCom";
import UploadFile from "@src/components/UploadFile";
import { levelOptions, EntityTypes } from "../config";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
let levels = levelOptions.slice(1);
const breads = [
    {
        label: "客户管理",
        path: "",
    },
    {
        label: "客户详情",
        path: "/supply",
    },
    {
        label: "创建实体",
        path: "/supply",
    },
];
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 15,
    },
};
const CreateEntry = () => {
    const local = JSON.parse(
        localStorage.getItem("operation_authorization") || "{}"
    );
    const searchParams = useParams();
    const [open, setOpen] = useState(false);
    const [modalForm] = Form.useForm();
    const [basicForm] = Form.useForm();
    const [InvoicingForm] = Form.useForm();
    const [contractForm] = Form.useForm();
    const [uploadValue, setUploadValue] = useState<any>({});
    const [invoicingNames, setInvoicingNames] = useState<any>([]);
    const [curtomer, setCustomer] = useState<any>({});
    const [taxList, setTaxList] = useState<any>([]);
    const [contractFile, setContractFile] = useState<any>({});
    // 税收分类
    const [category, setCategory] = useState<any>([]);
    // 税收-列表
    const [cateList, setCateList] = useState<any>([]);
    const [lists, setLists] = useState([]);
    const isDisabled = useMemo(() => {
        return !uploadValue?.license || uploadValue?.license === "";
    }, [uploadValue]);
    const timer = useRef<any>(null);
    // 根据统一信用代码  查找当前是否重复
    const SearchTaxs = (value: string) => {
        if (timer.current) {
            clearTimeout(timer.current);
            timer.current = null;
        }
        timer.current = setTimeout(async () => {
            setLists([]);
            const res = await getEntryList({
                current: 1,
                size: 20,
                keyword: value,
            });
            if (res?.code === 200) {
                if (res?.data?.records.length) {
                    message.error("统一信用代码重复");
                }
                setLists(res?.data?.records);
            }
        }, 500);
    };
    // 税区数据
    const getTaxs = async () => {
        const res: any = await getTaxList({
            current: 1,
            size: 99999,
        });
        setTaxList(res);
    };
    // 客户详情
    const getImgInfo = (obj?: any) => {
        const params = {
            url: obj?.downloadLink,
        };
        getImageMsg(params).then((res: any) => {
            const typeValue =
                res.type === "个体工商户"
                    ? "INDIVIDUAL_BUSINESS"
                    : "ENTERPRISE";
            const param = {
                license: obj?.fileId,
                name: res.name,
                taxNo: res?.regNum,
                // licenseNo: res.regNum,
                type: typeValue,
                legalPerson: res.person,
                // sourceType: "CRM",
            };
            basicForm.setFieldsValue(param);
            setUploadValue(param);
            SearchTaxs(res?.regNum);
        });
    };
    // 获取税收分类
    const getTaxCategory = async () => {
        const res: any = await getCategorySgort();
        if (res?.code === 200) {
            setCategory(res?.data);
        }
    };
    const getCustomers = async (id: any) => {
        if (!id) return;
        const res = await getCustomerInfo(id);
        if (res?.code === 200) {
            setCustomer(res?.data);
            basicForm.setFieldValue("sourceName", res?.data?.companyName);
            basicForm.setFieldValue("sourceType", "CRM");
        }
    };
    // 根据所选税收分类  筛选数据
    const handleCategory = (e: any) => {
        setCateList([]);
        getCategoryTaxs({
            current: 1,
            size: 1000,
            invoiceCategoryShort: e,
        }).then((res) => {
            if (res?.code === 200) {
                setCateList(res?.data?.records);
            }
        });
    };
    // 选择税收列表
    const handleCateList = (e: any) => {
        const current = cateList.filter((item: any) => {
            return e.includes(item?.id);
        });
        let list = [...invoicingNames];
        if (list.length === 0) {
            list = current;
        } else {
            current.forEach((item: any) => {
                const res = invoicingNames.some(
                    (cItem: any) => cItem.id === item.id
                );
                if (!res) {
                    list.unshift(item);
                }
            });
        }
        setInvoicingNames(list);
    };
    // 移除
    const handleDelete = (record: any) => {
        const list = invoicingNames.filter(
            (item: any) => item?.id !== record?.id
        );
        setInvoicingNames(list);
    };
    // handleSubmit
    const handleSubmit = async () => {
        const basic = await basicForm.validateFields();
        const invoce = await InvoicingForm.validateFields();
        const contrac = await contractForm.validateFields();
        const list: any = [];
        invoicingNames.forEach((item: any) => {
            list.push({
                code: item.invoiceCategoryCode,
                name: item?.invoiceProjectName,
            });
        });
        const params = {
            ...basic,
            ...invoce,
            ...contrac,
            taxRegionIds: contrac?.taxRegionIds
                ? contrac?.taxRegionIds.toString()
                : "",
            invoicingNames: list,
            contractFileId: contractFile?.filePath,
            sourceId: searchParams?.customerId,
            createUserName: local?.userName,
            businessLeaderName: curtomer?.businessOwner?.name,
            businessLeaderId: curtomer?.businessOwner?.id,
        };
        if (lists.length !== 0) {
            message.error("统一信用代码重复，请修改后提交");
            return;
        }
        const res: any = await addEntry(params);
        if (res?.code === 200) {
            window.history.back();
        }
    };
    useEffect(() => {
        getCustomers(searchParams?.customerId);
        getTaxs();
        getTaxCategory();
        return () => {
            if (timer.current) {
                clearTimeout(timer.current);
            }
        };
    }, [searchParams]);
    const columns = [
        {
            title: "发票项目名称",
            dataIndex: "invoiceProjectName",
        },
        {
            title: "税收编码",
            dataIndex: "invoiceCategoryCode",
        },
        {
            title: "操作",
            render: (record: any) => {
                return (
                    <Button onClick={() => handleDelete(record)} type="link">
                        移除
                    </Button>
                );
            },
        },
    ];
    // 根据上传或者输入的营业执照 查找社会统一信用代码  是否重复

    const handleChangeTax = (e: any) => {
        const reg = /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g;
        if (reg.test(e.target.value)) {
            SearchTaxs(e.target.value);
        }
    };
    return (
        <div className={styles.supply_order_container}>
            <BreadCrumbBar breads={breads}></BreadCrumbBar>
            <div className={styles.supply_order_info}>
                <div className={styles.title}>基本信息</div>
                <div className={styles.info_values}>
                    <Form {...layout} form={basicForm}>
                        <Row>
                            <Col span="8">
                                <Form.Item
                                    label="上传营业执照"
                                    name="license"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请上传营业执照",
                                        },
                                    ]}
                                >
                                    <div>
                                        <UploadCom
                                            maxSize={50}
                                            ButtonText={
                                                <div>
                                                    <PlusOutlined
                                                        style={{
                                                            fontSize: "20px",
                                                        }}
                                                    />{" "}
                                                    <br />
                                                    <span className={styles.Businessl_icense}>营业执照</span>
                                                </div>
                                            }
                                            cb={(values: any) => {
                                                // setUploadValue(values[0])
                                                if (
                                                    values[0] &&
                                                    values[0].downloadLink
                                                ) {
                                                    getImgInfo(values[0]);
                                                } else {
                                                    basicForm.setFieldsValue({
                                                        license: "",
                                                        name: "",
                                                        taxNo: "",
                                                        // licenseNo: res.regNum,
                                                        type: "",
                                                        legalPerson: "",
                                                    });
                                                    setUploadValue({});
                                                }
                                            }}
                                        ></UploadCom>
                                        <span className={styles.desc}>
                                            支持
                                            .png,.jpg,.jpeg文件上传，最大50MB
                                        </span>
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col span="8"></Col>
                            <Col span="8"></Col>
                            <Col span="8">
                                <Form.Item
                                    label="企业名称"
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入企业名称",
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="上传后自动输入"
                                        disabled={isDisabled}
                                    ></Input>
                                </Form.Item>
                                <Form.Item
                                    label="身份证号"
                                    name="idNumber"
                                    rules={[
                                        { required: false, message: "" },
                                        {
                                            pattern:
                                                /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
                                            message: "请输入正确的身份证号",
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="请输入身份证号"
                                        maxLength={18}
                                    ></Input>
                                </Form.Item>
                                <Form.Item
                                    label="来源类型"
                                    name="sourceType"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入来源类型",
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="CRM"
                                        disabled
                                        value="CRM"
                                    ></Input>
                                </Form.Item>
                            </Col>
                            <Col span="8">
                                <Form.Item
                                    label="统一信用代码"
                                    name="taxNo"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入统一信用代码",
                                        },
                                        {
                                            pattern:
                                                /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g,
                                            message: "请输入正确的统一信用代码",
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="上传后自动输入"
                                        disabled={isDisabled}
                                        onChange={handleChangeTax}
                                    ></Input>
                                </Form.Item>
                                <Form.Item
                                    label="交易级别"
                                    name="level"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择交易级别",
                                        },
                                    ]}
                                >
                                    <Select
                                        options={levels}
                                        placeholder="请选择交易级别"
                                    ></Select>
                                </Form.Item>
                                <Form.Item
                                    label="来源名称"
                                    name="sourceName"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入来源名称",
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="上传后自动输入"
                                        disabled
                                    ></Input>
                                </Form.Item>
                            </Col>
                            <Col span="8">
                                <Form.Item
                                    label="法人/经营者"
                                    name="legalPerson"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入法人/经营者",
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="上传后自动输入"
                                        disabled={isDisabled}
                                    ></Input>
                                </Form.Item>
                                <Form.Item
                                    label="实体类型"
                                    name="type"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择实体类型",
                                        },
                                    ]}
                                >
                                    <Select options={EntityTypes}></Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div className={`${styles.title} ${styles.mar_top_16}`}>
                    开票信息
                </div>
                <div className={styles.info_values}>
                    <Form {...layout} form={InvoicingForm}>
                        <Row>
                            <Col span="8">
                                <Form.Item
                                    label="开票行名称"
                                    name="invoicingBankName"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入开票行名称",
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入开票行名称"></Input>
                                </Form.Item>
                                <Form.Item
                                    label="开票电话"
                                    name="invoicingPhone"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入开票电话",
                                        },
                                        {
                                            pattern:
                                                /(^1[3456789]\d{9}$)|(^((0\d{2,3})-)?(\d{7,8})$)/,
                                            message: "请输入正确的开票电话",
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="请输入开票电话"
                                        maxLength={13}
                                    ></Input>
                                </Form.Item>
                            </Col>
                            <Col span="8">
                                <Form.Item
                                    label="开票行账户"
                                    name="invoicingBankNo"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入开票行账户",
                                        },
                                        {
                                            pattern: /^\d{16}|\d{19}$/,
                                            message: "请输入正确的开票账户",
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="请输入开票行账户"
                                        maxLength={19}
                                    ></Input>
                                </Form.Item>
                                <Form.Item
                                    label="发票邮箱"
                                    name="invoicingEmail"
                                    rules={[
                                        {
                                            pattern:
                                                /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$/,
                                            message: "请输入正确得邮箱",
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入发票邮箱"></Input>
                                </Form.Item>
                            </Col>
                            <Col span="8">
                                <Form.Item
                                    label="开票地址"
                                    name="invoicingBankAddress"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入开票地址",
                                        },
                                    ]}
                                >
                                    <Input></Input>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <div className={styles.flex_b}>
                        <span>开票名称</span>
                        <Button
                            type="primary"
                            onClick={() => {
                                setOpen(true);
                            }}
                        >
                            添加开票名称
                        </Button>
                    </div>
                    <Table
                        rowKey={(record: any) => record?.id}
                        pagination={false}
                        dataSource={invoicingNames}
                        columns={columns}
                    ></Table>
                </div>
                <div className={`${styles.title} ${styles.mar_top_16}`}>
                    合作税区
                </div>
                <div className={styles.info_values}>
                    <Form {...layout} form={contractForm}>
                        <Row>
                            <Col span="8">
                                <Form.Item
                                    name="taxRegionIds"
                                    label="税区"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择税区",
                                        },
                                    ]}
                                >
                                    <Select
                                        mode="multiple"
                                        options={taxList.map((item: any) => {
                                            return {
                                                ...item,
                                                label: item.name,
                                                value: item.id,
                                            };
                                        })}
                                    ></Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div className={`${styles.title} ${styles.mar_top_16}`}>
                    合同信息
                </div>
                <div className={styles.info_values}>
                    <Form {...layout}>
                        <Row>
                            <Col span="8">
                                <Form.Item name="contractFileId" label="合同">
                                    {/* <Select></Select> */}
                                    <UploadFile
                                        accept=".pdf"
                                        name="文件"
                                        listType=""
                                        ButtonText={
                                            <Button>
                                                <UploadOutlined /> 上传合同
                                            </Button>
                                        }
                                        cb={(values: any) => {
                                            if (values && values.length !== 0) {
                                                setContractFile(values[0]);
                                            } else {
                                                setContractFile({});
                                            }
                                        }}
                                    ></UploadFile>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div className={styles.flex_cen}>
                    <Button
                        onClick={() => {
                            history.back();
                        }}
                        style={{ marginRight: "16px" }}
                    >
                        取消
                    </Button>
                    <Button type="primary" onClick={handleSubmit}>
                        提交
                    </Button>
                </div>
            </div>
            {open && (
                <Modal
                    title="添加发票项目名称"
                    open={open}
                    onCancel={() => {
                        setOpen(false);
                        modalForm.resetFields();
                        setCateList([]);
                    }}
                    onOk={() => {
                        modalForm.validateFields().then((res) => {
                            handleCateList(res?.name);
                            setOpen(false);
                            setCateList([]);
                            modalForm.resetFields();
                        });
                    }}
                >
                    <Form {...layout} form={modalForm}>
                        <Form.Item
                            label="税收分类简称"
                            name="code"
                            rules={[
                                {
                                    required: true,
                                    message: "请选择税收分类简称",
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                filterOption={(input: string, option: any) => {
                                    return (option?.label ?? "").includes(
                                        input
                                    );
                                }}
                                options={category.map((item: any) => ({
                                    ...item,
                                    value: item.invoiceCategoryShort,
                                    label: item.invoiceCategoryShort,
                                }))}
                                onChange={handleCategory}
                            ></Select>
                        </Form.Item>
                        <Form.Item
                            label="发票项目名称"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: "请选择发票项目名称",
                                },
                            ]}
                        >
                            <Select
                                mode="multiple"
                                showSearch
                                filterOption={(input: string, option: any) => {
                                    return (option?.label ?? "").includes(
                                        input
                                    );
                                }}
                                options={cateList.map((item: any) => ({
                                    ...item,
                                    label: item.invoiceProjectName,
                                    value: item?.id,
                                }))}
                            ></Select>
                        </Form.Item>
                    </Form>
                </Modal>
            )}
        </div>
    );
};

export default CreateEntry;
