import axios from "axios"

export const getOrderDetailById = async (id: string)=>{
  return axios.get<Common.ResponseData< API.Schema.OrderDetail>>(`/sk-trade/orders/${id}`).then((res)=>res.data)
}

export const getEntityById = async (id: string)=>{
  return axios.get<Common.ResponseData<API.Entity.Info>>(`/trade-entity/entity/one`,{
    params:{
      id,
    }
  }).then((res)=>res.data)
}
export const getContractFileByNo = async (contractNo: string)=>{
  return axios.get<{fileId:string,fileDownUrl:string}>(`/sinzetech-sign/digital-contracts/${contractNo}/documents`)
}
export const postRevokeContract = async (contractNo:string)=>{
  return axios.put(`/sinzetech-sign/digital-contracts/${contractNo}/revoke`)
}

export const postResendContract = async (params: {
  "contractNo"?: string,
  "signer"?: {
      "mobile": string,
      "idNumber": string,
      "name": string
  }
})=>{
  return axios.post<API.Entity.Info>(`/sinzetech-sign/digital-contracts/resend`,params)
}

export const getSignUrl = async (params: {
  "idNumber": string,
  "contractNo": string
})=>{
  return axios.get<Common.ResponseData<string>>(`/sinzetech-sign/digital-contracts/getSignUrl`,{params}).then((res)=>res.data)
}

export const getSupplyContracts = async (params: {
  "sellerId": number,
  "buyerId": number,
  effective: 0|1,
  current: number,
  size: number
})=>{
  return axios.get<Common.ResponseData<{records: API.Schema.SupplyContractItem[]}>>(`/sk-trade/supply-contracts`,{params}).then((res)=>res.data)
}

export const getFileUrl = async (fileIds: string)=>{
  return axios.patch(`/sinzetech-resource/oss/endpoint`, {
    filePaths: fileIds.split(','),
    zoom: false
  }).then(res=>res.data.data.files)
}