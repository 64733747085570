import React, { useEffect, useState, useRef } from "react";
import { Button, Modal, Form, Select, message } from "antd";
import TableCom from "@src/components/TableCom";
import BreadCrumbBar from "@src/components/BreadCrumbBar";
import SearchForm from "@src/components/FormItem";
import {
  levelOptions,
  sourceOptions,
  indivdualityStatusOptions,
} from "../config";
import {
  getAllUsers,
  getTaxUsers,
  getTaxs,
  getEntryList,
  editBusinessUser,
  editTaxUser,
  getBusinessAndTax,
  getUsersGroup,
  getUserInfo,
} from "../promises";
import { useNavigate } from "react-router-dom";
import "../index.scss";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import { Link } from "react-router-dom";
const { Option } = Select;
const breads = [
  {
    label: "交易实体",
    path: "",
  },
  {
    label: "个体户实体",
    path: "",
  },
];
let timeout: any = null;
const searchUsers = (value?: any, callback?: any) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  const fake = () => {
    const params = {
      size: 100,
      current: 1,
      keywords: value,
    };
    getAllUsers(params).then((res: any) => {
      if (res?.code === 200) {
        callback(res?.data?.records);
      }
    });
  };
  if (value) {
    timeout = setTimeout(fake, 300);
  } else {
    callback([]);
  }
};
const Individuality = () => {
  const navigate = useNavigate();
  const [pageOptions, setPageOptions] = useState({
    pageSize: 20,
    current: 1,
  });
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState<any>({});
  const [users, setUsers] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [openType, setOpenType] = useState<any>("");
  const [form] = Form.useForm();
  const [allUsers, setAllUsers] = useState<any>([]);
  const [taxAllUser, setTaxAllUsers] = useState<any>([]);
  const [taxs, setTaxs] = useState<any>([]);
  const [business, setBusiness] = useState<any>([]);
  const [tax, setTax] = useState<any>([]);
  const [grops, setGrops] = useState([]);
  useEffect(() => {
    getUserList();
    getTaxUsersList();
    getAllTaxs();
    getList();
    getBusinessList();
    getTaxList();
    getGrops();
  }, []);
  // 获取用户组
  const getGrops = async () => {
    const res = await getUsersGroup({
      current: 1,
      size: 10000,
    });
    if (res?.code === 200) {
      const list = res?.data?.records.filter(
        (item: any) => item.name === "业务员"
      );
      if (list && list.length !== 0) {
        const response = await getUserInfo(list[0]?.id);
        if (response?.code === 200) {
          setGrops(response?.data?.members);
        }
      }
    }
  };
  // 获取搜索业务负责人
  const getBusinessList = async () => {
    const res: any = await getBusinessAndTax("businessLeader");
    if (res?.code === 200) {
      setBusiness(res?.data);
    }
  };
  // 获取搜索税区负责人
  const getTaxList = async () => {
    const res: any = await getBusinessAndTax("taxpayer");
    if (res?.code === 200) {
      console.log(res, "tax");
      setTax(res?.data);
    }
  };
  // 获取所有的业务负责人
  const getUserList = async () => {
    const res: any = await getAllUsers({
      current: 1,
      size: 1000,
    });
    if (res?.code === 200) {
      setAllUsers(res?.data?.records);
    }
  };
  // 获取办税人
  const getTaxUsersList = async () => {
    const res: any = await getTaxUsers({
      roleCodes: "TaxOfficer",
    });
    if (res?.code === 200) {
      setTaxAllUsers(res?.data);
    }
  };
  // 获取所有的税区
  const getAllTaxs = async () => {
    const res: any = await getTaxs({
      current: 1,
      size: 1000,
    });
    if (res?.code === 200) {
      let list = [
        {
          name: "全部",
          id: null,
        },
      ];
      list = list.concat(res?.data?.records);
      setTaxs(list);
    }
  };
  const handleOk = async (params: any) => {
    if (openType === "business") {
      const res: any = await editBusinessUser(params);
      if (res?.code === 200) {
        getList();
        setOpenType("");
      }
    } else {
      const res: any = await editTaxUser(params);
      if (res?.code === 200) {
        getList();
        setOpenType("");
      }
    }
  };
  // 列表数据
  const getList = async (object?: any) => {
    const params = {
      type: "INDIVIDUAL_BUSINESS",
      current: pageOptions.current,
      size: pageOptions.pageSize,
      // ...search,
      ...object,
    };
    if (object?.size && object?.current) {
      setPageOptions({
        current: object?.current,
        pageSize: object?.size,
      });
    }
    // 数据请求
    try {
      const res = await getEntryList(params);
      if (res?.code === 200) {
        setList(res?.data?.records);
        setTotal(res?.data?.total);
        setLoading(false);
      }
    } catch {
      setLoading(false);
    }
  };
  let listTimer = useRef<any>(null);
  const onSearch = (values: any) => {
    console.log(values, "ccc");
    let params: any = {};
    const keys = Object.keys(values);
    keys.forEach((item: string) => {
      if (values[item] !== null) {
        params[item] = values[item];
      }
    });
    const startTime = values.time
      ? dayjs(values.time[0]).format("YYYY-MM-DD") + " 00:00:00"
      : "";
    const endTime = values.time
      ? dayjs(values.time[1]).format("YYYY-MM-DD") + " 23:59:59"
      : "";
    if (values.time && values.time.length !== 0) {
      params.startTime = startTime;
      params.endTime = endTime;
    }
    params = {
      ...params,
      size: pageOptions.pageSize,
      current: pageOptions.current,
    };
    setSearch(params);
    delete params.time;
    // getList(params);
    if (listTimer.current) {
      clearTimeout(listTimer.current);
      listTimer.current = null;
    }
    listTimer.current = setTimeout(() => {
      getList(params);
    }, 700);
  };
  const handlekeyWords = (values: any) => {
    const val = values.target.value;
    if (listTimer.current) {
      clearTimeout(listTimer.current);
      listTimer.current = null;
    }
    listTimer.current = setTimeout(() => {
      getList({
        keyword: val,
      });
    }, 700);
  };
  // 业务人
  const handleSearch = (values: any) => {
    searchUsers(values, (values: any) => {
      setUsers(values);
    });
  };
  const rowSelection = {
    onChange: (newSelectedRowKeys: React.Key[], selectedRows: []) => {
      console.log(
        `selectedRowKeys: ${newSelectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: (record: any) => ({
      disabled: false, // Column configuration not to be checked
      name: record.name,
    }),
  };
  const handlePage = (params: any) => {
    getList({
      ...search,
      current: params.current,
      size: params.pageSize,
    });
  };
  // 导出
  const handleExport = () => {
    const workbook = XLSX.utils.book_new();
    const lists: any = [];
    const fields = {};
    columns.map((item: any) => {
      if (item.dataIndex) {
        lists.push({
          label: item.title,
          value: item.dataIndex,
        });
        fields[item.dataIndex] = item.title;
      }
    });
    const header = lists.map((item: any) => item.value);
    const ws = XLSX.utils.json_to_sheet(
      [
        fields,
        ...list.map((item: any) => {
          let data = {};
          header.forEach((it: any) => {
            data[it] = item[it];
            if (it === "taxRegionIds") {
              let str: any = [];
              taxs.forEach((k: any) => {
                if (item.taxRegionIds && item.taxRegionIds.includes(k.id)) {
                  str.push(k.name);
                }
              });
              data["taxRegionIds"] = str.toString();
            }
          });
          return data;
        }),
      ],
      {
        header,
        skipHeader: true,
      }
    );
    XLSX.utils.book_append_sheet(workbook, ws, "Sheet1");
    XLSX.writeFile(
      workbook,
      `企业实体_${dayjs(new Date()).format("YYYY-MM-DD")}.xlsx`,
      {
        bookType: "xlsx",
      }
    );
  };
  const formColumns = [
    {
      label: "交易级别",
      dataIndex: "level",
      type: "Select",
      params: {
        placeholder: "请选择",
        options: levelOptions,
        defaultValue: null,
      },
    },
    {
      label: "来源类型",
      dataIndex: "sourceType",
      type: "Select",
      params: {
        placeholder: "请选择",
        options: [
          ...sourceOptions,
          {
            label: "个体户代办",
            value: "AGENCY",
          },
        ],
        defaultValue: null,
      },
    },
    {
      label: "实体状态",
      dataIndex: "status",
      type: "Select",
      params: {
        placeholder: "请选择",
        options: indivdualityStatusOptions,
        defaultValue: null,
      },
    },
    {
      label: "税区",
      dataIndex: "regionId",
      type: "Select",
      params: {
        placeholder: "请选择",
        options: taxs.map((item: any) => ({
          value: item.id,
          label: item.name,
        })),
        defaultValue: null,
      },
    },
    {
      label: "业务负责人",
      dataIndex: "businessLeaderId",
      type: "Select",
      params: {
        placeholder: "请选择",
        defaultValue: null,
        showSearch: true,
        notFoundContent: null,
        filterOption: (input: string, option: any) => {
          return option?.label !== undefined
            ? option?.label.includes(input)
            : false;
        },
        options: (business || []).map((item: any) => ({
          value: item?.id,
          label: item?.name,
        })),
      },
    },
    {
      label: "办税人",
      dataIndex: "taxpayerId",
      type: "Select",
      params: {
        placeholder: "请选择",
        defaultValue: null,
        showSearch: true,
        notFoundContent: null,
        filterOption: (input: string, option: any) => {
          return option?.label !== undefined
            ? option?.label.includes(input)
            : false;
        },
        options: (tax || []).map((item: any) => ({
          value: item?.id,
          label: item?.name,
        })),
      },
    },
    {
      label: "创建日期",
      dataIndex: "time",
      type: "DatePickers",
      params: {},
    },
    {
      label: "搜索",
      dataIndex: "Input",
      type: "Input",
      params: {
        placeholder: "关键词搜索",
        onChange: handlekeyWords,
      },
    },
  ];
  const columns = [
    {
      title: "id",
      dataIndex: "id",
      render: (record: string) => {
        return (
          <Link to={`/entity/transaction/individuality/${record}`}>
            {record}
          </Link>
        );
      },
    },
    {
      title: "名称",
      dataIndex: "name",
    },
    {
      title: "统一社会信用代码",
      dataIndex: "taxNo",
    },
    {
      title: "所属税区",
      dataIndex: "taxRegionIds",
      render: (record: any) => {
        let str: any = [];
        console.log(record, "rrr");
        taxs.forEach((item: any) => {
          if (record && record.includes(item.id)) {
            str.push(item.name);
          }
        });
        return <span>{str.toString()}</span>;
      },
    },
    {
      title: "业务负责人",
      dataIndex: "businessLeaderName",
    },
    {
      title: "办税人",
      dataIndex: "taxpayerName",
    },
    {
      title: "状态",
      dataIndex: "statusName",
    },
    {
      title: "交易级别",
      dataIndex: "levelName",
    },
    {
      title: "来源类型",
      dataIndex: "sourceTypeName",
    },
    {
      title: "来源名称",
      dataIndex: "sourceName",
    },
    {
      title: "创建日期",
      dataIndex: "createDate",
    },
  ];
  return (
    <div className="entirty_list_container">
      <BreadCrumbBar breads={breads}></BreadCrumbBar>{" "}
      <div className="entirty_search_container">
        <SearchForm
          formParams={formColumns}
          onSearch={onSearch}
          optionsList={null}
        ></SearchForm>
      </div>
      <div className="entirty_table_container">
        <div className="entirty_table_nav_flex">
          <div>个体户实体列表</div>
          <div>
            <Button
              className="mar_r_8"
              onClick={() => {
                if (selectedRowKeys.length === 0) {
                  message.error("请选择实体数据");
                  return;
                }
                setOpenType("business");
              }}
            >
              修改业务负责人
            </Button>
            <Button
              type="primary"
              className="mar_r_8"
              onClick={() => {
                if (selectedRowKeys.length === 0) {
                  message.error("请选择实体数据");
                  return;
                }
                setOpenType("tax");
              }}
            >
              修改办税人
            </Button>
            <Button type="primary" onClick={handleExport}>
              导出
            </Button>
          </div>
        </div>
        <TableCom
          rowKey={(record: any) => record?.id}
          columns={columns}
          dataSource={list}
          pagination={{
            ...pageOptions,
            total,
          }}
          scroll
          params={{
            rowSelection: {
              type: "checked",
              ...rowSelection,
            },
          }}
          onChange={handlePage}
        ></TableCom>
      </div>
      {openType !== "" && (
        <Modal
          open={openType !== ""}
          title={openType === "business" ? "修改业务负责人" : "修改办税人"}
          onCancel={() => {
            setOpenType("");
            form.resetFields();
          }}
          onOk={() => {
            // handleOk
            form.validateFields().then((res) => {
              const obj = JSON.parse(res?.users || "{}");
              // const list = selectedRowKeys.map((item: any) =>
              //     Number(item)
              // );
              const params = {
                ids: selectedRowKeys,
                userId: Number(obj?.id),
                userName: obj?.name,
              };
              handleOk(params);
              form.resetFields();
            });
          }}
        >
          <Form
            labelCol={{
              span: 5,
            }}
            wrapperCol={{
              span: 12,
            }}
            form={form}
          >
            {openType === "business" ? (
              <Form.Item
                label="业务负责人"
                name="users"
                rules={[
                  {
                    required: true,
                    message: "请选择业务负责人",
                  },
                ]}
              >
                <Select placeholder="请选择">
                  {grops.map((item: any) => (
                    <Option key={item.id} value={JSON.stringify(item)}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              <Form.Item
                label="办税人"
                name="users"
                rules={[
                  {
                    required: true,
                    message: "请选择办税人",
                  },
                ]}
              >
                <Select placeholder="请选择">
                  {taxAllUser.map((item: any) => (
                    <Option key={item.id} value={JSON.stringify(item)}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Form>
        </Modal>
      )}
    </div>
  );
};

export default Individuality;
