import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import TopNavbar from "../../components/TopNavbar";
import LeftNavbar from "../../components/LeftNavbar";
import {
    TableOutlined,
    DashboardOutlined,
    DatabaseOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";

const Entity = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const roleList = useSelector((state: any) => state?.roleCodes) || [];
    const menuList = [
        {
            name: "交易实体",
            path: "/entity/transaction",
            icon: <TableOutlined />,
            key: "entity_transaction",
            children: [
                {
                    name: "企业",
                    path: "/entity/transaction/enterprise",
                },
                {
                    name: "个体户",
                    path: "/entity/transaction/individuality",
                },
                {
                    name: "自然人",
                    path: "/entity/transaction/natural",
                },
            ],
        },
        {
            name: "平台分类",
            path: "/entity/transaction/platformClassification",
            icon: <DashboardOutlined />,
        },
        {
            name: "订单管理",
            path: "/entity/transaction/procureOrder",
            // key: "entity_orderManagement",
            icon: <DatabaseOutlined />,
            children: [
                {
                    name: "订单管理",
                    path: "/entity/transaction/procureOrder",
                },
            ],
        },
    ];
    return (
        <div className="evidence-container">
            <TopNavbar config={{ value: "entity" }} />
            <div className="evidence-container-content">
                <LeftNavbar lists={menuList} />
                <Outlet></Outlet>
            </div>
        </div>
    );
};

export default Entity;
