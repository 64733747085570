import { Modal, ModalFuncProps, message } from "antd";
import dayjs from "dayjs";
import numeral from "numeral";

export const confirmToContinue = (params?: ModalFuncProps) => {
    return new Promise((resolve, reject) => {
      Modal.confirm({
        ...params,
        onCancel: () => {
          reject();
        },
        onOk: () => {
          resolve(null);
        },
      });
    });
};

export const whileLoading = async <T>(request: ()=> Promise<T>, msg: string='处理中') =>{
  const destroy = message.loading(msg,0)
  const response = await request().finally(()=>{
    destroy()
  })
  return response 
}

export function isCrossOrigin(url:string) {
  const currentOrigin = new URL(window.location.href);
  const requestOrigin = new URL(url);
  return currentOrigin.hostname !== requestOrigin.hostname || currentOrigin.protocol !== requestOrigin.protocol || currentOrigin.port !== requestOrigin.port;
}


export const dateWithFallback = (input?: dayjs.ConfigType)=>{
  return input ? dayjs(input) : undefined
}

export const formatAmount = (amount: string | number | null = 0) => {
  return numeral(amount || 0).format('0,0.00')
}

export const downloadFile = ({ filename, data }: { filename?: string; data: Blob | File |string}) => {
  const href = typeof data ==='string'? data : window.URL.createObjectURL(data)
  const a = document.createElement('a');
  a.setAttribute('href', href);
  a.setAttribute('target', '_blank');
  a.setAttribute('download', filename||'文件'); // 自定义文件名（有效）

  document.body.appendChild(a);
  a.click();
  try {
    document.body.removeChild(a);
  } catch (e) {
    window.console.log(e);
  }
  window.URL.revokeObjectURL(href);
};
