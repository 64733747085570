import React, { useEffect, useState } from "react";
import { Button, Row, Col, Image } from "antd";
import BreadCrumbBar from "@src/components/BreadCrumbBar";
import TableCom from "@src/components/TableCom";
import { useParams } from "react-router-dom";
import styles from "./index.module.scss";
import { getApplyInfo } from "../../promise";
import dayjs from "dayjs";
import CompatiblePdf from './pages/CompatiblePdf/index'
enum TYPE {
  BUSINESS_LICENSE = "营业执照",
  LEGAL_PERSON_IDENTITY_CARD_FRONT = "法人身份证正面",
  LEGAL_PERSON_IDENTITY_CARD_OPPOSITE = "法人身份证反面",
  CREDIT_AUTHORIZATION_LETTER = "征信授权书",
  INVOICE = "发票",
  TRADE_CONTRACT = "贸易合同",
  ORDER_ATTACHMENTS = "订单附件",
  LOAN_CONTRACT = "借款合同",
  LETTER_OF_GUARANTEE = "担保函",
}
enum COMPANY {
  LIMITED_LIABILITY_COMPANY = "有限责任公司",
  INDIVIDUAL_INDUSTRIAL = "个体工商户",
  OTHER = "其他",
  PARTNERSHIP = "合伙企业",
  NOT_LISTING_COMPANY = "股份有限公司 非上市",
  LISTING_COMPANY = "股份有限公司(上市)",
  INSTITUTIONS = "事业单位",
  FINANCIAL_INSTITUTIONS = "金融机构",
}
const breads = [
  {
    label: "供应商准入",
    path: "",
  },
  {
    label: "详情",
    path: "/supply",
  },
];
const SupplierAccessDetail = () => {
  const searchParams = useParams();
  const [info, setInfo] = useState<any>({});
  const [visible, setVisible] = useState(false);
  const [previewPdf, setPreviewPdf] = useState(false);//记录是否是pdf
  const [previewPdfFlag, setPreviewPdfFlag] = useState(false);//pdf控制展示 隐藏
  const [openImgURL, setOpenURL] = useState("");
  const getInfo = async () => {
    const id = searchParams?.id || "";
    if (!id) return;
    const res = await getApplyInfo(id);
    if (res?.code === 200) {
      setInfo(res?.data);
    }
  };
  const downLoad = (imgSrc: string, fileName: string) => {
    const alink = document.createElement("a");
    alink.href = imgSrc;
    alink.download = fileName;
    alink.click();
  };
  useEffect(() => {
    getInfo();
  }, []);
  const columns = [
    {
      title: "文件类型",
      render: (record: any) => {
        return <span>{TYPE[record.fileType]}</span>;
      },
    },
    {
      title: "文件名称",
      dataIndex: "fileName",
    },
    {
      title: "上传时间",
      dataIndex: "createTime",
    },
    {
      title: "操作",
      render: (record: any) => {
        return (
          <div>
            <Button
              type="link"
              onClick={() => {
                let fileFlagPdf = record?.fileName.includes('.pdf') || ''
                setPreviewPdf(fileFlagPdf)
                setVisible(true);
                setOpenURL(record.fileUrl);
                setPreviewPdfFlag(false)
              }}
            >
              预览
            </Button>
            <Button
              type="link"
              onClick={() => downLoad(record.fileUrl, record.fileName)}
            >
              下载
            </Button>
          </div>
        );
      },
    },
  ];
  return (
    <div className={styles.supply_order_container} style={{ overflow: "auto" }}>
      <CompatiblePdf
        previewPdf={previewPdf}
        visible={visible}
        openImgURL={openImgURL}
        previewPdfFlag={previewPdfFlag}
        setVisible={setVisible}
        setPreviewPdfFlag={setPreviewPdfFlag}
      />
      <BreadCrumbBar breads={breads}></BreadCrumbBar>
      <div className={styles.supply_order_info}>
        <div className={styles.title}>基本信息</div>
        <div className={styles.info_values}>
          <Row>
            <Col span="8">
              <div className={styles.info_items}>
                <span className={styles.label}>企业名称：</span>
                {info?.name}
              </div>
            </Col>
            <Col span="8">
              <div className={styles.info_items}>
                <span className={styles.label}>企业类型：</span>
                {COMPANY[info?.supplierType]}
              </div>
            </Col>
            <Col span="8">
              <div className={styles.info_items}>
                <span className={styles.label}>统一社会信用代码：</span>
                {info?.unifiedSocialCreditCode}
              </div>
            </Col>
          </Row>
          <Row>
            <Col span="8">
              <div className={styles.info_items}>
                <span className={styles.label}>营业执照注册日期：</span>
                {dayjs(info?.registerDate).format("YYYY-MM-DD")}
              </div>
            </Col>
            <Col span="8">
              <div className={styles.info_items}>
                <span className={styles.label}>企业注册地：</span>
                {info?.registerAddrDetail}
              </div>
            </Col>
          </Row>
          <Row>
            <Col span="8">
              <div className={styles.info_items}>
                <span className={styles.label}>企业联系人：</span>
                {info?.contactsName}
              </div>
            </Col>
            <Col span="8">
              <div className={styles.info_items}>
                <span className={styles.label}>联系电话：</span>
                {info?.contactsMobile}
              </div>
            </Col>
            <Col span="8">
              <div className={styles.info_items}>
                <span className={styles.label}>通讯地址：</span>
                {info?.mailingAddress}
              </div>
            </Col>
          </Row>
        </div>
        <div className={`${styles.title} ${styles.mar_top_16}`}>经营者信息</div>
        <div className={styles.info_values}>
          <Row>
            <Col span="8">
              <div className={styles.info_items}>
                <span className={styles.label}>姓名：</span>
                {info?.legalName}
              </div>
            </Col>
            <Col span="8">
              <div className={styles.info_items}>
                <span className={styles.label}>身份证号：</span>
                {info?.legalIdCard}
              </div>
            </Col>
            <Col span="8">
              <div className={styles.info_items}>
                <span className={styles.label}>手机号：</span>
                {info?.legalMobile}
              </div>
            </Col>
          </Row>
          <Row>
            <Col span="8">
              <div className={styles.info_items}>
                <span className={styles.label}>身份证有效期：</span>
                {dayjs(info?.legalIdCardStart).format("YYYY-MM-DD")}-
                {dayjs(info?.legalIdCardEnd).format("YYYY-MM-DD")}
              </div>
            </Col>
            <Col span="8">
              <div className={styles.info_items}>
                <span className={styles.label}>身份证地址：</span>
                {info?.legalIdCardAddrDetail}
              </div>
            </Col>
          </Row>
        </div>
        <div className={`${styles.title} ${styles.mar_top_16}`}>附件信息</div>
        <div className={styles.info_values}>
          <div className={styles.mar_top_16}>
            <TableCom
              columns={columns}
              dataSource={
                info?.supplierAttachment?.length ? info.supplierAttachment : []
              }
              total={0}
              pagination={false}
              scroll={{
                x: true,
              }}
            ></TableCom>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierAccessDetail;
