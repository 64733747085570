// 融资订单 状态options
export const financingStatusOptions = [
    {
        label: '已申请',
        value:'APPLIED'
    },
    {
        label: '审核拒绝',
        value:'APPROVAL_FAIL'
    },
    {
        label: '协议待签署',
        value:'CONTRACT_TO_BE_SIGNED'
    },
    {
        label: '待放款',
        value:'PENDING_LOAN'
    },
    {
        label: '放款失败',
        value:'LOAN_FAIL'
    },
    {
        label: '待还款',
        value:'PENDING_REPAYMENT'
    },
    {
        label: '部分还款',
        value:'PARTIAL_REPAYMENT'
    },
    {
        label: '还款完成',
        value:'REPAYMENT_COMPLETE'
    },
    {
        label: '已逾期',
        value:'OVERDUE'
    },
]

export const repaymentModeObj = {
    'REPAID_ONCE_DUE':'到期一次还本付息'
}
