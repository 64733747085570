import React,{memo, useContext} from 'react'
import { OrderDetailContext } from '../useOrderDetail'
import { Badge, Button, Card, Descriptions, Space, Table, message } from 'antd'
import { ColumnsType } from 'antd/es/table'
import moment from 'moment'
import { getContractFileByNo, getFileUrl, getSignUrl, postResendContract, postRevokeContract } from '../service'
import { useLoading } from '@/hooks/useLoading'
import { confirmToContinue, downloadFile } from '@/utils/utils'
import copy from 'copy-to-clipboard'
import { SupplyContractEffectiveEnum, SupplyContractStatusEnum } from '@/constant/order'

export const OrderContractInfo: React.FC<{}> = memo(()=>{
  const context = useContext(OrderDetailContext)

  const contractTableData = context?.data?.supplyContracts
  const checkContract = async (record: API.Schema.SupplyContractItem)=>{
    const url = record.fileIds 
    ? await getFileUrl(record.fileIds).then(file=>file?.[0].downloadUrl)
    : await getContractFileByNo(record.contractNo).then((res)=>res.data.fileDownUrl)
    window.open(url,'_blank')
  }
  const columns:ColumnsType<API.Schema.SupplyContractItem> = [
    {dataIndex: 'sellerName',title:'买方'},
    {dataIndex: 'buyerName',title:`卖方`},
    {dataIndex: 'signStatus',title:'签署状态',render(value){
      return <Badge {...SupplyContractStatusEnum[value]||SupplyContractStatusEnum['NO_SIGN']} />
    }},
    {dataIndex: 'effectiveTime',title:'协议有效期'},
    {dataIndex: 'status',title:'协议状态',render(value:0|1){
      return <Badge {...SupplyContractEffectiveEnum[value]} />
    }},
    {dataIndex: 'createTime',title:'创建时间',render(text){
      return text?moment(text).format('YYYY-MM-DD HH:mm:ss'):'-'
    }},
    {dataIndex: 'actions',title:'操作',render(value,record){
      return <a onClick={checkContract.bind(null,record)}>查看合同</a>
    }},
  ]
  const [resendContract] = useLoading(async ()=>{
    await confirmToContinue({title:'提示',content:<>是否重发签署链接？</>})
    await postRevokeContract(context?.data.contract?.contractNo!)
    message.success('操作成功')
  })
  const [deleteAndResendContract,deleteAndResendLoading] = useLoading(async ()=>{
    await confirmToContinue({title:'提示',content:<>是否删除并重发合同？</>})
    await postResendContract({contractNo: context?.data.contract?.contractNo})
    message.success('操作成功')
  })
  const [downloadContract,downloadLoading] = useLoading(async ()=>{
    const res = await getContractFileByNo(context?.data.contract?.contractNo!)
    downloadFile({filename:`${context?.data.id||''}_${context?.data.contract?.contractNo||''}`,data:res.data.fileDownUrl})
  })
  const [signUrl] = useLoading(async (idNumber: string)=>{
    const res = await getSignUrl({contractNo: context?.data.contract?.contractNo!,idNumber})
    if(!copy(res.data)) return;
    message.success('复制成功')
  })
  return <Card title='合同签署'>
    <Table columns={columns} dataSource={contractTableData} />
    <br />
    {/* 
      删除重发：开票状态在未申请，已拒绝，已申请时显示
      下载合同：合同签署状态为已签署时显示
    */}
    <Descriptions column={3} title="订单合同" extra={<Space>
        <Button disabled={!context?.data.contract?.contractNo} loading={deleteAndResendLoading} onClick={deleteAndResendContract}>删除重发</Button>
        <Button hidden={context?.data.contract?.signStatus==='ALL_COMPLETED'} disabled={!context?.data.contract?.contractNo} type='primary' loading={downloadLoading} onClick={downloadContract}>下载合同</Button>
      </Space>}>
      <Descriptions.Item label="买方签署人">
        { context?.data?.contract?.buyer?.signer}
      </Descriptions.Item>
      <Descriptions.Item label="联系电话">
      { context?.data?.contract?.buyer?.phone}
      </Descriptions.Item>
      <Descriptions.Item label="签署状态">
      <Space>
        {context?.data?.contract?.buyer?.signStatus?<Badge color='#00d18b' text="已签署">已签署</Badge>:<Badge color='#f53f3f' text='未完成'></Badge>}
        { context?.data?.contract?.buyer?.signStatus===false && context?.data?.contract?.contractNo?<><a type='link' onClick={resendContract}>重发合同短信</a><a onClick={signUrl.bind(null,context?.data.contract?.buyer.idCard!)}>复制签署链接</a></>:<></> }</Space> 
      </Descriptions.Item>
      <Descriptions.Item label="卖方签署人">
      { context?.data?.contract?.supplier?.signer}
      </Descriptions.Item>
      <Descriptions.Item label="联系电话">
      { context?.data?.contract?.supplier?.phone}
      </Descriptions.Item>
      <Descriptions.Item label="签署状态">
      <Space>
        {context?.data?.contract?.supplier?.signStatus?<Badge color='#00d18b' text="已签署">已签署</Badge>:<Badge color='#f53f3f' text='未完成'></Badge>}
        {context?.data?.contract?.supplier?.signStatus===false && context?.data?.contract?.contractNo?<><a type='link' onClick={resendContract}>重发合同短信</a><a onClick={signUrl.bind(null,context?.data.contract?.supplier.idCard!)}>复制签署链接</a></>:<></> }</Space> 
      </Descriptions.Item>
      <Descriptions.Item label="合同编号">
      {context?.data?.contract?.contractNo}
      </Descriptions.Item>    
      <Descriptions.Item label="合同生成时间">
      {context?.data?.contract?.generateTime?moment(context?.data?.contract?.generateTime).format('YYYY-MM-DD HH:mm:ss'):''}
      </Descriptions.Item>
    </Descriptions>
  </Card>
})