import React, { useState, useEffect, useRef, useMemo } from "react";
import BreadCrumbBar from "../../../../../../components/BreadCrumbBar";
import {
    Collapse,
    Form,
    Input,
    Radio,
    Select,
    Tabs,
    Button,
    message,
    InputNumber,
    Table,
    Space,
} from "antd";
import { createFromIconfontCN } from "@ant-design/icons";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "./index.scss";
import Quill from "./Coms/Quill";
import {
    addTax,
    getTaxInfo,
    getUserList,
    saveDocument,
    getDocumentDetails,
    getImageUrl,
} from "../../promise";
import { QUILL_TAX } from "../../../../../../store/constants";
import { useDispatch, useSelector } from "react-redux";
import TableCom from "../../../../../../components/TableCom";
import UploadFile from "../../../../../../components/UploadFile";
import { uuid, downloadUrlFile } from "../../../../../../utils/index";
import {
    objToArr,
    arrToObject,
    valiatorValue,
    renderNuclears,
} from "../../config";
import RenderNuclearTable from "./Coms/RenderNuclearTable"; // 是否需要核名的税区配置table
import TaxPayCompany from "./Coms/TaxPayCompany";
const FormItem = Form.Item;
const breads = [
    {
        label: "系统设置",
        path: "/system",
    },
    {
        label: "税区管理",
        path: "/system/tax",
    },
    {
        label: "新增税区",
        path: "/system/tax/add",
    },
];

const templateList = [
    {
        label: "手机验证码验证",
        value: "PHONE",
    },
    {
        label: "小程序实名并绑定办税人",
        value: "MINI",
    },
    {
        label: "手机验证码和实名并绑定办税人",
        value: "REALNAME",
    },
    {
        label: "跳过验证",
        value: "SKIP",
    },
];
const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 16,
    },
};

const panelLayout = {
    labelCol: {
        span: 5,
    },
    wrapperCol: {
        span: 10,
    },
};
const MyIcon = createFromIconfontCN({
    scriptUrl:
        "https://at.alicdn.com/t/c/font_3981257_jo8pn1tj9ns.js?spm=a313x.7781069.1998910419.53&file=font_3981257_jo8pn1tj9ns.js",
});
// 签章options
const textoptions = [
    {
        label: "用户签字",
        value: "USER_SIGNATURE",
    },
    {
        label: "平台签章",
        value: "PLATFORM_SIGNATURE",
    },
];
// 当当管理子模块
// 委托协议
const RenderTextEntrust = (props: any) => {
    const [formTable] = Form.useForm();
    const {
        label = "上传委托协议模板",
        editing = true,
        isDetail,
        name,
        cb,
        value,
    } = props;
    // 委托协议
    const [wOptions, setWOptions] = useState(textoptions);
    // 文档管理
    const [textSource, setTextSource] = useState([]);
    const [uploadFiles, setUploadFiles] = useState<any>([]);
    const [fileUrl, setFileUrl] = useState("");
    const entrustColumns = [
        {
            title: "序号",
            width: 100,
            render: (record?: any, text?: any, index?: any) => {
                console.log(index, "inde");
                return <span>{index + 1}</span>;
            },
        },
        {
            title: "签字类型",
            editable: true,
            width: 120,
            render: (record: any, text?: any, index?: any) => {
                return editing ? (
                    <Form.Item
                        name={`propertyType_${record?.id}`}
                        rules={[
                            {
                                required: true,
                                message: `请选择第${index + 1}签字类型`,
                            },
                        ]}
                    >
                        <Select
                            disabled={isDetail}
                            defaultValue={record?.type}
                            style={{ width: "120px" }}
                            onChange={() => {
                                handleRenderTextSub(true);
                            }}
                        >
                            {wOptions.map((item: any) => {
                                return (
                                    <Select.Option
                                        key={item.value}
                                        value={item.value}
                                    >
                                        {item.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                ) : (
                    <span>{record}</span>
                );
            },
        },
        {
            title: "页码",
            // dataIndex:'number',
            editable: true,
            width: 120,
            render: (record: any, text?: any, index?: any) => {
                return editing ? (
                    <Form.Item
                        name={`pageNumber_${record?.id}`}
                        rules={[
                            {
                                required: true,
                                message: `请输入第${index + 1}行页码`,
                            },
                        ]}
                    >
                        <InputNumber
                            min={1}
                            disabled={isDetail}
                            defaultValue={record?.number}
                            onChange={() => {
                                handleRenderTextSub(true);
                            }}
                        ></InputNumber>
                    </Form.Item>
                ) : (
                    <span>{record}</span>
                );
            },
        },
        {
            title: "x坐标",
            // dataIndex:'positionX',
            editable: true,
            width: 120,
            render: (record: any, text?: any, index?: any) => {
                return editing ? (
                    <Form.Item
                        name={`coordinateX_${record?.id}`}
                        rules={[
                            {
                                required: true,
                                message: `请输入第${index + 1}行X坐标`,
                            },
                        ]}
                    >
                        <InputNumber
                            min={0}
                            max={999}
                            disabled={isDetail}
                            onKeyUp={(e: any) => {
                                return (e.target.value = e.target.value.replace(
                                    /[^\d]/g,
                                    ""
                                ));
                            }}
                            onChange={() => {
                                handleRenderTextSub(true);
                            }}
                        ></InputNumber>
                    </Form.Item>
                ) : (
                    <span>{record}</span>
                );
            },
        },
        {
            title: "y坐标",
            // dataIndex:'positionY',
            editable: true,
            render: (record: any, text?: any, index?: any) => {
                return editing ? (
                    <Form.Item
                        name={`coordinateY_${record?.id}`}
                        rules={[
                            {
                                required: true,
                                message: `请输入第${index + 1}行Y坐标`,
                            },
                        ]}
                    >
                        <InputNumber
                            min={0}
                            max={999}
                            disabled={isDetail}
                            onKeyUp={(e: any) => {
                                return (e.target.value = e.target.value.replace(
                                    /[^\d]/g,
                                    ""
                                ));
                            }}
                            onChange={() => {
                                handleRenderTextSub(true);
                            }}
                        ></InputNumber>
                    </Form.Item>
                ) : (
                    <span>{record}</span>
                );
            },
        },
    ];
    // 根据url   下载文件
    const getFilesUrl = () => {
        if (!value?.fileId) return;
        const params = {
            zoom: false,
            fileIds: value?.fileId,
        };
        getImageUrl(params).then((res?: any) => {
            if (res?.code === 200) {
                setFileUrl(res?.data[0]);
            }
        });
    };
    useEffect(() => {
        console.log(value, "use-effect");
        if (value && value?.documentConfigs) {
            setTextSource(value?.documentConfigs);
            if (value?.documentConfigs) {
                const res = value?.documentConfigs.map((item?: any) => {
                    item.id = uuid();
                    return item;
                });
                const params = arrToObject(res, "id");
                formTable.setFieldsValue(params);
            }
        }
        if (value.fileId) {
            setUploadFiles([
                { fileId: value?.fileId, fileName: value?.fileName },
            ]);
        }
        getFilesUrl();
    }, [value]);
    const handleRenderTextSub = (callbackValue?: any) => {
        formTable.validateFields().then((res: any) => {
            console.log();
            const val = objToArr(res, textSource);
            const newvalue = val.map((item?: any, index?: any) => {
                item.serialNumber = index + 1;
                return item;
            });
            let params = {
                documentType: name,
                fileName: uploadFiles[0]?.fileName,
                fileId: uploadFiles[0]?.fileId,
                documentConfigs: newvalue,
            };
            cb(params, callbackValue);
        });
    };
    return (
        <div>
            <div className="entrust_label">
                {label}:&nbsp; &nbsp;
                {isDetail ? (
                    <span
                        style={{ cursor: "pointer", color: "#1677ff" }}
                        onClick={() => {
                            // fileUrl
                            downloadUrlFile(fileUrl);
                        }}
                    >
                        {value?.fileName}
                    </span>
                ) : (
                    <UploadFile
                        cb={(value: any, file: any) => {
                            console.log(value, file, "upload");
                            setUploadFiles(value);
                        }}
                        name="文件"
                        accept=".pdf"
                        type="file"
                        listType="text"
                        value={value?.fileId}
                        fileParams={{
                            url: fileUrl,
                            fileName: value?.fileName,
                            fileId: value?.fileId,
                            name: value?.fileName,
                        }}
                        ButtonText={
                            <Button
                                disabled={isDetail}
                                icon={<MyIcon type="icon-shangchuan" />}
                            >
                                选择文件
                            </Button>
                        }
                    />
                )}
                &nbsp; &nbsp;
                <span>支持.pdf格式的文件</span>
            </div>
            <div className="entrust_table">
                <Form
                    form={formTable}
                    component={false}
                    // onValuesChange={(e: any) => {

                    // }}
                >
                    <Table
                        rowKey={(record: any) => record?.id}
                        style={{ width: "600px" }}
                        columns={entrustColumns}
                        dataSource={textSource}
                        pagination={false}
                        bordered
                    ></Table>
                </Form>

                <div className="mar_left_10">
                    <MyIcon type="icon-weizhi" />
                    获取文档坐标，请
                    <a
                        href="https://open.esign.cn/tools/seal-position"
                        target="_blank"
                    >
                        点击这里
                    </a>
                </div>
            </div>
            {!isDetail ? (
                <div className="entrust_button">
                    <Button
                        type="primary"
                        onClick={() => {
                            const arr: any = [...textSource];
                            arr.push({
                                serialNumber: "",
                                propertyType: "",
                                pageNumber: "",
                                coordinateX: "",
                                coordinateY: "",
                                id: uuid(),
                            });
                            setTextSource(arr);
                        }}
                    >
                        添加一行
                    </Button>
                    <div>
                        <Button
                            onClick={() => {
                                setTextSource([]);
                                cb(
                                    {
                                        documentType: name,
                                        fileName: uploadFiles[0]?.fileName,
                                        fileId: uploadFiles[0]?.fileId,
                                        documentConfigs: [],
                                    },
                                    true,
                                    true
                                );
                            }}
                        >
                            重置
                        </Button>{" "}
                        &nbsp;&nbsp;
                        <Button
                            type="primary"
                            onClick={() => handleRenderTextSub(false)}
                        >
                            保存
                        </Button>
                    </div>
                </div>
            ) : null}
        </div>
    );
};
const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;
const AddTax = () => {
    arrToObject();
    const dispacth = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = useParams();
    const [messageApi, contextHolder] = message.useMessage();
    const quillRef = useRef<any>();
    const isDetail =
        searchParams?.type && searchParams.type === "info" ? true : false;
    const [basicForm] = Form.useForm();
    const [formApplet] = Form.useForm();
    const [formTemplate] = Form.useForm();
    const [formDispose] = Form.useForm();
    // 是否核名的form
    const [nuclearForm] = Form.useForm();
    // 个体户名称设置数据存贮
    // const IndividualForm = useRef({
    //   businessNamePrefix:'',
    //   businessNameSuffix:''
    // })
    const [businessNamePrefix, setBusinessNamePrefix] = useState("");
    const [businessNameSuffix, setBusinessNameSuffix] = useState("");
    const [businessForm] = Form.useForm();
    const [isUploadTaxScreenshot, setIsUploadTaxScreenshot] = useState(0);
    const [details, setDetails] = useState<any>({});
    const [collapseList, setCollapseList] = useState([
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
    ]);
    const bList = useMemo(() => {
        if (isDetail) {
            return [
                {
                    label: "系统设置",
                    path: "/system",
                },
                {
                    label: "税区管理",
                    path: "/system/tax",
                },
                {
                    label: "税区详情",
                    path: "/system/tax/add",
                },
            ];
        } else {
            if (searchParams?.id) {
                return [
                    {
                        label: "系统设置",
                        path: "/system",
                    },
                    {
                        label: "税区管理",
                        path: "/system/tax",
                    },
                    {
                        label: "编辑税区",
                        path: "/system/tax/add",
                    },
                ];
            }
        }
        return breads;
    }, [searchParams?.id, isDetail]);
    // 文档管理
    const [textCollapse, setTextCollapse] = useState(["2-1", "2-2"]);
    // 存储文本管理信息
    const [documentInfo, setDocumentInfo] = useState([]);
    // 获取人员配置相关code列表
    //   营业执照办理人：LicenseOfficer
    // 税区业务负责人：BusinessManager
    // 税区财务负责人：FinancialManager
    const [licenseOfficer, setLicenseOfficer] = useState([]);
    const [businessManager, setBusinessManager] = useState([]);
    const [financialManager, setFinancialManager] = useState([]);
    // 分配办税人
    const [taxpayer, setTaxpayer] = useState([]);
    const roleList = useSelector((state: any) => state.roleCodes);
    const isAdmin = useMemo(() => {
        return roleList.includes("admin");
    }, [roleList]);
    // 委托协议
    const formTable1 = useRef<any>({});
    const priRef1 = useRef<any>({
        bool: true,
    });
    // 注销协议
    const formTable2 = useRef<any>({});
    const priRef2 = useRef<any>({
        bool: true,
    });
    // 小程序模板配置  税务登记模板
    const [miniTaxTemplate, setMiniTaxTemplate] = useState("");
    // const [unsubscribe, setUnsubscribe] = useState({});
    // const [priBool, setPriBool] = useState(true);
    // const [unBool, setUnBool] = useState(true);
    const getLicenseOfficer = () => {
        const params = {
            roleCodes: "LicenseOfficer",
        };
        getUserList(params).then((res: any) => {
            if (res.code === 200) {
                setLicenseOfficer(res.data);
            }
        });
    };
    const getBusinessManager = () => {
        const params = {
            roleCodes: "BusinessManager",
        };
        getUserList(params).then((res: any) => {
            if (res.code === 200) {
                setBusinessManager(res.data);
            }
        });
    };
    const getFinancialManager = () => {
        const params = {
            roleCodes: "FinancialManager",
        };
        getUserList(params).then((res: any) => {
            if (res.code === 200) {
                setFinancialManager(res.data);
            }
        });
    };
    // 获取分配办税人数据
    const getTaxpayer = () => {
        const params = {
            roleCodes: "assignor",
        };
        getUserList(params).then((res: any) => {
            if (res.code === 200) {
                setTaxpayer(res?.data);
            }
        });
    };
    useEffect(() => {
        getLicenseOfficer();
        getBusinessManager();
        getFinancialManager();
        getTaxpayer();
    }, []);
    // Collapse change
    const handleChange = (e: any) => {
        setCollapseList(e);
    };
    const handleRadio = (e: any) => {
        setIsUploadTaxScreenshot(e.target.value);
    };
    // 个体注册小程序
    const RenderAppletForm = () => {
        return (
            <Form {...panelLayout} form={formApplet}>
                <FormItem
                    label="营业执照办理进行中提示"
                    name="licenseProcessTip"
                    rules={[
                        {
                            required: true,
                            message: "请输入营业执照办理进度提示",
                        },
                    ]}
                >
                    <Input.TextArea
                        placeholder="请输入"
                        rows={4}
                        disabled={isDetail}
                    ></Input.TextArea>
                </FormItem>
                <FormItem
                    label="税务登记模板"
                    name="taxRegisterVerifyTemplate"
                    rules={[
                        {
                            required: true,
                            message: "请选择税务登记模板",
                        },
                    ]}
                >
                    <Select
                        onChange={(e: any) => {
                            console.log(e, "selec-template");
                            setMiniTaxTemplate(e);
                        }}
                        disabled={isDetail}
                    >
                        {templateList.map((item: any) => {
                            return (
                                <Option key={item.value} value={item.value}>
                                    {item.label}
                                </Option>
                            );
                        })}
                    </Select>
                </FormItem>
                {["PHONE", "REALNAME"].includes(miniTaxTemplate) && (
                    <FormItem
                        label="手机号验证提示"
                        name="phoneNumberVerifyTip"
                        rules={[
                            {
                                required: true,
                                message: "请输入手机号验证提示",
                            },
                        ]}
                    >
                        <Input.TextArea
                            placeholder="请输入"
                            rows={4}
                            disabled={isDetail}
                        ></Input.TextArea>
                    </FormItem>
                )}
                {["MINI", "REALNAME"].includes(miniTaxTemplate) && (
                    <>
                        <FormItem
                            label="政务通提示"
                            name="miniAppTaxTip"
                            rules={[
                                {
                                    required: true,
                                    message: "请输入政务通提示",
                                },
                            ]}
                        >
                            <Input.TextArea
                                placeholder="请输入"
                                rows={4}
                                disabled={isDetail}
                            ></Input.TextArea>
                        </FormItem>

                        <FormItem
                            label="政跳转小程序名称"
                            name="miniAppName"
                            rules={[
                                {
                                    required: true,
                                    message: "请输入政政跳转小程序名称",
                                },
                            ]}
                        >
                            <Input
                                placeholder="请输入"
                                disabled={isDetail}
                            ></Input>
                        </FormItem>
                        <FormItem
                            label="小程序ID"
                            name="miniAppId"
                            rules={[
                                {
                                    required: true,
                                    message: "请输入小程序ID",
                                },
                            ]}
                        >
                            <Input
                                placeholder="请输入"
                                disabled={isDetail}
                            ></Input>
                        </FormItem>
                    </>
                )}
                <FormItem
                    label="税务登记进行中提示"
                    name="taxProcessTip"
                    rules={[
                        {
                            required: true,
                            message: "税务登记进行中提示",
                        },
                    ]}
                >
                    <Input.TextArea
                        placeholder="请输入"
                        rows={4}
                        disabled={isDetail}
                    ></Input.TextArea>
                </FormItem>
            </Form>
        );
    };
    // 通知模板
    const RenderTemplate = () => {
        return (
            <Form {...panelLayout} form={formTemplate}>
                <FormItem
                    label="通知资料审核员进行审核"
                    name="approvalTemplate"
                    rules={[
                        {
                            required: true,
                            message: "请输入通知资料审核员",
                        },
                    ]}
                >
                    <TextArea
                        rows={4}
                        placeholder="请输入"
                        disabled={isDetail}
                    ></TextArea>
                </FormItem>
                <FormItem
                    label="审核不通过通知-发给创建人"
                    name="approvalRejectTemplate"
                    rules={[
                        {
                            required: true,
                            message: "请输入审核不通过通知",
                        },
                    ]}
                >
                    <TextArea
                        rows={4}
                        placeholder="请输入"
                        disabled={isDetail}
                    ></TextArea>
                </FormItem>
                <FormItem
                    label="税务登记获取验证码-发给创建人"
                    name="taxRegisterSmsCodeTemplate"
                    rules={[
                        {
                            required: true,
                            message: "请输入税务登记获取验证码",
                        },
                    ]}
                >
                    <TextArea
                        rows={4}
                        placeholder="请输入"
                        disabled={isDetail}
                    ></TextArea>
                </FormItem>
                <FormItem
                    label="开办成功通知"
                    name="processingCompletedTemplate"
                    rules={[
                        {
                            required: true,
                            message: "请输入开办成功通知",
                        },
                    ]}
                >
                    <TextArea
                        rows={4}
                        placeholder="请输入"
                        disabled={isDetail}
                    ></TextArea>
                </FormItem>
            </Form>
        );
    };
    // 人员配置
    const RenderDispose = () => {
        return (
            <Form {...panelLayout} form={formDispose}>
                {searchParams.type !== "info" ? (
                    <>
                        <FormItem
                            label="营业执照办理人"
                            name="licensorUserId"
                            rules={[
                                {
                                    required: true,
                                    message: "请选择营业执照办理人",
                                },
                            ]}
                        >
                            {/* <Input placeholder="请输入"  disabled={isDetail}></Input> */}
                            <Select
                                disabled={isDetail}
                                showSearch
                                filterOption={(input, option: any) => {
                                    return (
                                        option.props.children.indexOf(input) >=
                                        0
                                    );
                                }}
                            >
                                {licenseOfficer.map((item: any) => {
                                    return (
                                        <Select.Option
                                            key={item.id}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </FormItem>
                        <FormItem
                            label="业务负责人"
                            name="businessUserId"
                            rules={[
                                {
                                    required: true,
                                    message: "请选择业务负责人",
                                },
                            ]}
                        >
                            <Select
                                disabled={isDetail}
                                showSearch
                                filterOption={(input, option: any) => {
                                    return (
                                        option.props.children.indexOf(input) >=
                                        0
                                    );
                                }}
                            >
                                {businessManager.map((item: any) => {
                                    return (
                                        <Select.Option
                                            key={item.id}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </FormItem>
                        <FormItem
                            label="财务负责人"
                            name="financeUserId"
                            rules={[
                                {
                                    required: true,
                                    message: "请选择财务负责人",
                                },
                            ]}
                        >
                            <Select
                                disabled={isDetail}
                                showSearch
                                filterOption={(input, option: any) => {
                                    return (
                                        option.props.children.indexOf(input) >=
                                        0
                                    );
                                }}
                            >
                                {financialManager.map((item: any) => {
                                    return (
                                        <Select.Option
                                            key={item.id}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </FormItem>
                        <FormItem
                            name="registerUserId"
                            label="税务登记分配人"
                            rules={[
                                {
                                    required: true,
                                    message: "请选择税务登记分配人",
                                },
                            ]}
                        >
                            <Select
                                disabled={isDetail}
                                showSearch
                                filterOption={(input, option: any) => {
                                    return (
                                        option.props.children.indexOf(input) >=
                                        0
                                    );
                                }}
                            >
                                {taxpayer.map((item: any) => {
                                    return (
                                        <Select.Option
                                            key={item.id}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </FormItem>
                    </>
                ) : (
                    <>
                        <FormItem
                            label="营业执照办理人姓名"
                            name="licensorUserName"
                        >
                            <Input disabled></Input>
                        </FormItem>
                        <FormItem label="负责人姓名" name="businessUserName">
                            <Input disabled></Input>
                        </FormItem>
                        <FormItem label="负责人手机号" name="businessUserPhone">
                            <Input disabled></Input>
                        </FormItem>
                        <FormItem
                            label="税务登记分配人"
                            name="registerUserName"
                        >
                            <Input disabled></Input>
                        </FormItem>
                    </>
                )}
            </Form>
        );
    };
    // 富文本 auditRequiremen
    const RenderQuill = () => {
        return (
            <div
                className={`${
                    searchParams?.type === "info" ? "quill_disabled" : ""
                }`}
            >
                <Quill ref={quillRef}></Quill>
            </div>
        );
    };
    // 提及资料审核内容
    const RenderMaterials = () => {
        return (
            <Form {...panelLayout} form={nuclearForm}>
                <FormItem
                    label="个体户办理能否采用不核名"
                    name="enableNuclearName"
                >
                    <Radio.Group defaultValue={"0"} disabled={isDetail}>
                        {renderNuclears.map((item) => (
                            <Radio key={item.value} value={item.value}>
                                {item.label}
                            </Radio>
                        ))}
                    </Radio.Group>
                </FormItem>
                <FormItem label="添加开户上传图片证明" name="uploadPhotoProof">
                    <RenderNuclearTable
                        disabled={isDetail}
                        value={details?.uploadPhotoProof}
                        cb={(list) => {
                            nuclearForm.setFieldValue("uploadPhotoProof", list);
                        }}
                    ></RenderNuclearTable>
                </FormItem>
                <FormItem
                    label="经营范围配置"
                    name="businessScope"
                    rules={[{ required: true, message: "请输入经营范围配置" }]}
                >
                    <TextArea maxLength={1000} disabled={isDetail}></TextArea>
                </FormItem>
            </Form>
        );
    };
    const getCollapeText = (index: string, type: any = "tax") => {
        var i: any = true;
        i = collapseList.indexOf(index) < 0;
        if (type === "tax") {
            i = collapseList.indexOf(index) < 0;
        }
        if (type === "text") {
            i = textCollapse.indexOf(index) < 0;
        }
        return i ? "展开" : "收起";
    };
    const handleKeyPress = (e: any) => {
        const pattern = /^[\u4e00-\u9fa5]+$/; // 中文正则表达式
        const inputValue = e.target.value + e.key;
        if (!pattern.test(inputValue)) {
            e.preventDefault();
        }
    };
    // 工商税务办理设置
    const panelList = () => {
        return [
            {
                key: "1",
                label: "提交资料内容设置",
                extra: getCollapeText("1"),
                text: <RenderMaterials></RenderMaterials>,
                // <Form {...panelLayout}>
                //     <FormItem
                //         label="开户时是否需要上传青岛税税通截图"
                //         name="isUploadTaxScreenshot"
                //     >
                //         <Radio.Group
                //             onChange={handleRadio}
                //             defaultValue={isUploadTaxScreenshot}
                //             disabled={isDetail}
                //         >
                //             {[
                //                 { label: "不需要", value: 0 },
                //                 { label: "需要", value: 1 },
                //             ].map((item: any) => (
                //                 <Radio key={item.value} value={item.value}>
                //                     {item.label}
                //                 </Radio>
                //             ))}
                //         </Radio.Group>
                //     </FormItem>
                // </Form>
            },
            {
                key: "2",
                label: "个体户名称设置",
                extra: getCollapeText("2"),
                text: (
                    <div>
                        <Form {...panelLayout} form={businessForm}>
                            <FormItem label="个体户名称前缀">
                                <Space>
                                    <FormItem
                                        label="个体户名称前缀"
                                        noStyle
                                        name="businessNamePrefix"
                                    >
                                        <Input
                                            disabled={isDetail}
                                            defaultValue={
                                                details?.businessNamePrefix
                                            }
                                            maxLength={10}
                                            style={{
                                                width: "200px",
                                                marginRight: "2px",
                                            }}
                                            onBlur={(e: any) => {
                                                setBusinessNamePrefix(
                                                    e.target.value
                                                );
                                            }}
                                            onKeyPress={handleKeyPress}
                                        ></Input>
                                        {/* <span>纯中文输入，最多10个字</span> */}
                                    </FormItem>
                                    <span
                                        style={{
                                            color: "#ccc",
                                            display: "inline-block",
                                            minWidth: "max-content",
                                        }}
                                    >
                                        纯中文输入，最多10个字
                                    </span>
                                </Space>
                            </FormItem>
                            <FormItem label="个体户名称后缀">
                                <Space>
                                    <FormItem
                                        label="个体户名称后缀"
                                        noStyle
                                        name="businessNameSuffix"
                                    >
                                        <Input
                                            disabled={isDetail}
                                            maxLength={10}
                                            defaultValue={
                                                details?.businessNameSuffix
                                            }
                                            style={{
                                                width: "200px",
                                                marginRight: "2px",
                                            }}
                                            onBlur={(e: any) => {
                                                setBusinessNameSuffix(
                                                    e.target.value
                                                );
                                            }}
                                            onKeyPress={handleKeyPress}
                                        ></Input>
                                    </FormItem>
                                    <span
                                        style={{
                                            color: "#ccc",
                                            display: "inline-block",
                                            minWidth: "max-content",
                                        }}
                                    >
                                        纯中文输入，最多10个字
                                    </span>
                                </Space>
                            </FormItem>
                            <FormItem label="预览">
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <span style={{ color: "#ccc" }}>
                                        企业/个体户名称:
                                    </span>
                                    <Input
                                        addonBefore={businessNamePrefix}
                                        addonAfter={businessNameSuffix}
                                        disabled
                                        style={{ width: "100px" }}
                                    ></Input>
                                </div>
                            </FormItem>
                        </Form>
                    </div>
                ),
            },
            {
                key: "3",
                label: "个体户注册小程序配置",
                extra: getCollapeText("3"),
                text: <RenderAppletForm></RenderAppletForm>,
            },
            // {
            //     key: "4",
            //     label: "通知模板设置",
            //     extra: getCollapeText("4"),
            //     text: <RenderTemplate></RenderTemplate>,
            // },
            {
                key: "5",
                label: "人员配置",
                extra: getCollapeText("5"),
                text: <RenderDispose></RenderDispose>,
            },
            {
                key: "6",
                label: "营业执照办理资料审核要求",
                extra: getCollapeText("6"),
                text: <RenderQuill></RenderQuill>,
            },
        ];
    };
    // 获取税区文档详情
    const getDocumentInfo = () => {
        const id = searchParams?.id;
        if (!id) return;
        getDocumentDetails(id).then((res?: any) => {
            setDocumentInfo(res?.data);
        });
    };

    const handleAgreement = (params?: any) => {
        const id = searchParams?.id;
        if (!id) return;
        saveDocument(id, params).then((res?: any) => {
            if (res?.code === 200) {
                message.success("编辑成功");
            }
        });
    };
    // 文本  注销协议
    // 文档管理
    const textList = () => {
        const PRINCIPALObj =
            documentInfo.find(
                (item: any) => item.documentType === "PRINCIPAL"
            ) || {};
        const UNSUBSCRIBEObj =
            documentInfo.find(
                (item: any) => item.documentType === "UNSUBSCRIBE"
            ) || {};
        return [
            {
                key: "2-1",
                label: "委托协议管理",
                extra: getCollapeText("2-1", "text"),
                text: (
                    <RenderTextEntrust
                        isDetail={isDetail}
                        name="PRINCIPAL"
                        cb={(params: any, bool?: any, isReset?: any) => {
                            formTable1.current = params;
                            // console.log(
                            //     params,
                            //     bool,
                            //     formTable1.current,
                            //     "priRef1priRef1priRef1priRef1priRef1"
                            // );
                            if (bool) {
                                // 仅仅存储参数
                                if (!isReset) {
                                    priRef1.current.bool = false;
                                } else {
                                    priRef1.current.bool = true;
                                }
                            }
                            // 不是单个form的操作  并且不是重置  此时为保存操作
                            if (!bool && !isReset) {
                                if (processingData(params)) {
                                    handleAgreement(params);
                                }
                            }
                        }}
                        value={PRINCIPALObj}
                    ></RenderTextEntrust>
                ),
            },
            {
                key: "2-2",
                label: "协议注销管理",
                extra: getCollapeText("2-2", "text"),
                text: (
                    <RenderTextEntrust
                        label="上传注销协议模板"
                        isDetail={isDetail}
                        name="UNSUBSCRIBE"
                        cb={(params: any, bool?: any, isReset?: any) => {
                            formTable2.current = params;
                            if (bool) {
                                // 仅仅存储参数
                                if (!isReset) {
                                    priRef2.current.bool = false;
                                } else {
                                    priRef2.current.bool = true;
                                }
                            } else {
                                if (processingData(params)) {
                                    handleAgreement(params);
                                }
                            }
                        }}
                        value={UNSUBSCRIBEObj}
                    ></RenderTextEntrust>
                ),
            },
        ];
    };

    // 获取详情
    const getDetails = () => {
        const id = searchParams?.id;
        if (!id || id === "") return;
        getTaxInfo(id).then((res: any) => {
            if (res.successful) {
                setIsUploadTaxScreenshot(res.data.isUploadTaxScreenshot);
                // 人员配置
                const disposeData = {
                    licensorUserId: res.data.licensorUserId,
                    businessUserId: res.data.businessUserId,
                    financeUserId: res.data.financeUserId,
                    licensorUserName: res.data.licensorUserName,
                    businessUserName: res.data.businessUserName,
                    businessUserPhone: res.data.businessUserPhone,
                    registerUserId: res.data?.registerUserId,
                    registerUserName: res?.data?.registerUserName,
                };
                formDispose.setFieldsValue(disposeData);

                // 基本信息
                const basicData = {
                    taxName: res.data.taxName,
                    postCode: res.data.postCode,
                    officeAddress: res.data.officeAddress,
                };
                basicForm.setFieldsValue(basicData);
                // 注册小程序
                const appletData = {
                    licenseProcessTip: res.data.licenseProcessTip,
                    phoneNumberVerifyTip: res.data.phoneNumberVerifyTip,
                    miniAppTaxTip: res.data.miniAppTaxTip,
                    taxRegisterVerifyTemplate:
                        res.data.taxRegisterVerifyTemplate,
                    miniAppName: res.data.miniAppName,
                    miniAppId: res.data.miniAppId,
                    taxProcessTip: res.data?.taxProcessTip,
                };
                formApplet.setFieldsValue(appletData);
                // 通知模板
                const templateDate = {
                    approvalTemplate: res.data.approvalTemplate,
                    approvalRejectTemplate: res.data.approvalRejectTemplate,
                    taxRegisterSmsCodeTemplate:
                        res.data.taxRegisterSmsCodeTemplate,
                    processingCompletedTemplate:
                        res.data.processingCompletedTemplate,
                };
                // formTemplate.setFieldsValue(templateDate);
                setDetails(res.data);
                setMiniTaxTemplate(res?.data?.taxRegisterVerifyTemplate);
                setBusinessNamePrefix(res?.data?.businessNamePrefix);
                setBusinessNameSuffix(res?.data?.businessNameSuffix);
                const businessdetails = {
                    businessNameSuffix: res?.data?.businessNameSuffix,
                    businessNamePrefix: res?.data?.businessNamePrefix,
                };
                businessForm.setFieldsValue(businessdetails);
                // 获取资料设置数据 nuclearForm
                const nuclearObj = {
                    uploadPhotoProof: res?.data?.uploadPhotoProof,
                    enableNuclearName: "" + res?.data?.enableNuclearName,
                    businessScope: res?.data?.businessScope,
                };
                nuclearForm.setFieldsValue(nuclearObj);
                // businessForm
                dispacth({
                    type: QUILL_TAX,
                    payload: {
                        text: res.data.auditRequirement,
                    },
                });
            }
        });
    };
    useEffect(() => {
        getDetails();
        getDocumentInfo();
        return () => {
            dispacth({
                type: QUILL_TAX,
                payload: {
                    text: "",
                },
            });
        };
    }, [searchParams]);
    const processingData = (params: any, type?: any) => {
        let bool = true;
        if (params?.documentConfigs && params?.documentConfigs.length !== 0) {
            let list: any = [];
            params.documentConfigs?.map((item: any, index: number) => {
                if (list.length === 0) {
                    list.push(item);
                } else {
                    const currentValue = list.some((cItem: any) => {
                        if (
                            cItem.pageNumber === item.pageNumber &&
                            cItem.propertyType === item.propertyType &&
                            cItem.coordinateX === item.coordinateX &&
                            cItem.coordinateY === item.coordinateY
                        ) {
                            return true;
                        }
                    });
                    if (currentValue) {
                        if (!type) {
                            message.error(
                                `第${index + 1}行存在相同数据，请重新修改后保存`
                            );
                        }
                    } else {
                        list.push(item);
                    }
                }
                return item;
            });
            if (list.length < params?.documentConfigs.length) {
                bool = false;
            }
        }
        return bool;
    };
    const handleSubmit = async () => {
        const res1 = await basicForm.validateFields(); // 基本信息
        if (
            valiatorValue("name", nuclearForm.getFieldValue("uploadPhotoProof"))
        ) {
            message.error("图片名称不能为空，且不能重复");
            return;
        }
        const res = await nuclearForm.validateFields(); // 提交资料设置

        const res2 = await formApplet.validateFields(); // 小程序
        // const res3 = await formTemplate.validateFields(); // 通知模板
        const res4 = await formDispose.validateFields(); // 人员配置
        // let params = {};
        const obj = formDispose.getFieldsValue();
        const auditRequiremen = quillRef.current?.getEditorContents();
        // 上传表格数据 searchParams?.id
        const tableList = nuclearForm.getFieldValue("uploadPhotoProof") || [];
        let newTable: any = [];
        tableList.map((item: any, index: number) => {
            let tableObj = {
                num: index,
                name: item?.name,
                description: item?.description,
                fileId: item?.fileId,
            };
            newTable.push(tableObj);
            return item;
        });

        const data = {
            ...details,
            // isUploadTaxScreenshot: isUploadTaxScreenshot,
            auditRequirement: auditRequiremen,
            ...obj,
            ...formApplet.getFieldsValue(),
            ...basicForm.getFieldsValue(),
            // ...formTemplate.getFieldsValue(),
            id: searchParams?.id,
            ...businessForm.getFieldsValue(),
            // ...nuclearForm.getFieldsValue(),
            enableNuclearName:
                nuclearForm.getFieldValue("enableNuclearName") || "0",
            businessScope: nuclearForm.getFieldValue("businessScope"),
            uploadPhotoProof: newTable,
        };
        addTax(data).then((res: any) => {
            if (res.successful) {
                const routerType = searchParams.type;
                // dispacth({
                //   type:QUILL_TAX,
                //   payload:{
                //     text:''
                //   }
                // })
                messageApi.open({
                    type: "success",
                    content:
                        routerType && routerType === "edit"
                            ? "编辑成功"
                            : "创建成功",
                    onClose: () => {
                        navigate(-1);
                    },
                });
            } else {
                messageApi.open({
                    type: "error",
                    content: res?.message || "操作失败",
                });
            }
        });
    };
    // 工商税务办理设置
    const RenderSettings = () => {
        return (
            <div className="collapse_container">
                <Collapse
                    onChange={handleChange}
                    expandIconPosition={"end"}
                    defaultActiveKey={collapseList}
                >
                    {panelList().map((item: any) => {
                        return (
                            <Panel
                                key={item.key}
                                header={item.label}
                                extra={item.extra}
                            >
                                {item.text}
                            </Panel>
                        );
                    })}
                </Collapse>
                {!isDetail ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            margin: "10px",
                        }}
                    >
                        <Button type="primary" onClick={() => handleSubmit()}>
                            提交
                        </Button>
                        <Button
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            取消
                        </Button>
                    </div>
                ) : null}
            </div>
        );
    };
    // 文档管理
    const RenderTextManage = () => {
        return (
            <div className="collapse_container">
                <Collapse
                    defaultActiveKey={textCollapse}
                    expandIconPosition="end"
                    onChange={(e: any) => {
                        setTextCollapse(e);
                    }}
                >
                    {textList().map((item: any) => {
                        return (
                            <Panel
                                key={item.key}
                                header={item.label}
                                extra={item.extra}
                            >
                                {item.text}
                            </Panel>
                        );
                    })}
                </Collapse>
                {!isDetail ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            margin: "10px",
                        }}
                    >
                        <Button
                            type="primary"
                            onClick={() => {
                                if (!priRef1.current.bool) {
                                    if (processingData(formTable1.current)) {
                                        handleAgreement(formTable1.current);
                                        // handleSubmit();
                                    }
                                }
                                if (!priRef2.current.bool) {
                                    if (processingData(formTable2.current)) {
                                        handleAgreement(formTable2.current);
                                    }
                                }
                                let bool1 =
                                    (!priRef1.current.bool &&
                                        processingData(
                                            formTable1.current,
                                            "hidden"
                                        )) ||
                                    priRef1.current.bool;
                                let bool2 =
                                    (!priRef2.current.bool &&
                                        processingData(
                                            formTable2.current,
                                            "hidden"
                                        )) ||
                                    priRef2.current.bool;
                                if (bool1) {
                                    handleSubmit();
                                    return;
                                }
                                if (bool2) {
                                    handleSubmit();
                                    return;
                                }
                            }}
                        >
                            提交
                        </Button>
                        <Button
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            取消
                        </Button>
                    </div>
                ) : null}
            </div>
        );
    };

    const tabItems: any = [
        {
            key: "1",
            label: "工商税务办理设置",
            children: <RenderSettings></RenderSettings>,
        },
        {
            key: "2",
            label: "文档管理",
            children: <RenderTextManage></RenderTextManage>,
        },
        {
            key: "3",
            label: "交税公司信息维护",
            children: <TaxPayCompany isDetail={isDetail}></TaxPayCompany>,
        },
    ];
    const defaultTabs = [
        {
            key: "1",
            label: "工商税务办理设置",
            children: <RenderSettings></RenderSettings>,
        },
    ];
    return (
        <div className="add_tax">
            {contextHolder}
            <div className="add_tax_content">
                <div className="tax_top">
                    <BreadCrumbBar breads={bList}></BreadCrumbBar>
                    {!isDetail && !searchParams?.id ? <h3>新增税区</h3> : null}
                    {isDetail && isAdmin ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <Button
                                type="primary"
                                onClick={() => {
                                    navigate(
                                        `/system/tax/edit/${searchParams.id}`
                                    );
                                }}
                            >
                                编辑
                            </Button>
                        </div>
                    ) : null}

                    <Form {...panelLayout} form={basicForm}>
                        <FormItem
                            label="税区名称"
                            name="taxName"
                            rules={[
                                { required: true, message: "请输入税区名称" },
                            ]}
                        >
                            <Input
                                placeholder="请输入名称"
                                disabled={isDetail}
                            ></Input>
                        </FormItem>
                        <FormItem
                            label="邮编"
                            name="postCode"
                            rules={[
                                { required: true, message: "请输入邮编" },
                                {
                                    pattern: /\d{6}/,
                                    message: "请输入正确的邮编",
                                },
                            ]}
                        >
                            <Input
                                maxLength={6}
                                placeholder="请输入邮编"
                                disabled={isDetail}
                            ></Input>
                        </FormItem>
                        <FormItem label="税区办公地址" name="officeAddress">
                            <Input
                                prefix={<MyIcon type="icon-weizhi" />}
                                maxLength={100}
                                allowClear
                                disabled={isDetail}
                            ></Input>
                        </FormItem>
                    </Form>
                </div>
                {/* tabs */}
                <div className="tax_tabs">
                    <Tabs
                        items={
                            searchParams?.type !== "info" && !searchParams?.id
                                ? defaultTabs
                                : tabItems
                        }
                    ></Tabs>
                </div>
            </div>
        </div>
    );
};

export default AddTax;
