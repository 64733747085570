import React, { memo, useContext } from 'react'
import { OrderDetailContext } from '../useOrderDetail'
import { AmountCard } from '../../components/AmountCard'
import './index.scss'
import classname from 'classnames'
import { Card, Divider, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'

export const OrderPaymentInfo: React.FC<{}> = memo(() => {
  const context = useContext(OrderDetailContext)
  const columns:ColumnsType<API.Order.Payment> = [
    {title:'支付ID',dataIndex:'paymentId'},
    {title:'创建时间',dataIndex:'createTime'},
    {title:'支付编号',dataIndex:'paymentNo'},
    {title:'付款方',dataIndex:'payerName'},
    {title:'收款方账号',dataIndex:'payeeAccount'},
    {title:'订单关联货款(元)',dataIndex:'grossAmount'},
    {title:'订单关联税款',dataIndex:'taxAmount'},
    {title:'支付来源',dataIndex:'paymentSource'},
    {title:'支付渠道',dataIndex:'paymentChannel'},
    {title:'支付状态',dataIndex:'status',fixed:'right'},
    {title:'到账时间',dataIndex:'settledTime',fixed:'right'},
    {title:'操作',dataIndex:'paymentId',fixed:'right'},
  ]
  return <Card title='支付信息'>
    <div className={classname('order-payment-pane-amount-cards')}>
      <AmountCard
        title="订单总金额"
        totalAmount={context?.data?.amounts?.totalAmount}
        paidAmount={context?.data?.amounts?.paidAmount}
        unpaidAmount={context?.data?.amounts?.unpaidAmount}
        deductAmount={context?.data?.amounts?.deductAmount}
      ></AmountCard>
      <Divider type="vertical" />
      <AmountCard
        title="货款金额"
        totalAmount={context?.data?.amounts?.grossAmount}
        paidAmount={context?.data?.amounts?.paidGrossAmount}
        unpaidAmount={context?.data?.amounts?.unpaidGrossAmount}
        deductAmount={context?.data?.amounts?.deductAmount}
      ></AmountCard>
      <Divider type="vertical" />
      <AmountCard
        title="税款金额"
        totalAmount={context?.data?.amounts?.taxAmount}
        paidAmount={context?.data?.amounts?.paidTaxAmount}
        unpaidAmount={context?.data?.amounts?.unpaidTaxAmount}
      ></AmountCard>
    </div>
    <br />
  <Table columns={columns} dataSource={context?.data?.payments}></Table>
  </Card>
})