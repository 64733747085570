import Util from "@src/services/util";
import { financeURL } from "@src/utils/invoiceURL";
import qs from 'qs'
import axios from "axios";
const baseURL = financeURL();
const util = Util.getInstance();
const current_env = process.env.REACT_APP_ENV || "production";
const cross_env = {
  development: "https://dev.api.saikul.com",
  test: "https://test.api.saikul.com",
  production: "https://api.saikul.com",
}[current_env];
const Basics_URL = cross_env || "https://api.saikul.com";
interface Order {
  orderStatus?: string;
  applyTimeStart?: string;
  applyTimeEnd?: string;
  loanTimeStart?: string;
  loanTimeEnd?: string;
  repaymentDateStart?: string;
  repaymentDateEnd?: string;
  keyword?: string;
  current: string | number;
  size: string | number;
}
// 融资订单列表
export const getOrderList = (params: Order) => {
  return util.httpGet(`/finance/order?${qs.stringify({
      'sorts[0].field':'createTime',
      'sorts[0].direction':'DESCENDING',
  })}`, params, true, baseURL);
};

// 融资详情
export const getOrderInfo = (id: string) => {
  return util.httpGet(`/finance/order/${id}`,{}, true, baseURL);
};

// 上传文件
export const uploadFile = (params: any) => {
  return util.httpPost("/file", params);
};
export const getUploadFiles = (id: string) => {
  return util.httpGet(`/file_${id}`);
};

// 供应商
interface Applys {
  approvalStatus?: string;
  accessTimeStart?: string;
  accessTimeEnd?: string;
  keyword?: string;
  current: string | number;
  size: string | number;
}
// 供应商列表
export const getSupplyList = (params: Applys) => {
  return util.httpGet(`/finance/supplierAccess`, params, true, baseURL, false);
};

// 供应商详情
export const getApplyInfo = (id: string) => {
  return util.httpGet(
    `/finance/supplierAccess/${id}`,
    "",
    true,
    baseURL,
    false
  );
};

// 更新供应商
export const updateApply = (id: string, params: any) => {
  return util.httpPut(`/finance/supplierAccess/${id}`, params, true, baseURL);
};

// 核心企业列表
export const getCompanyList = (params?: any) => {
  return util.httpGet("/coreEnterprise", params, true, baseURL, false);
};

// 添加核心企业
export const addCompanyApi = (params: any) => {
  return util.httpPost("/coreEnterprise", params, true, baseURL);
};

// 融资产品列表
export const getProductList = (params: any) => {
  return util.httpGet("/product", params, true, baseURL, false);
};
// 添加融资产品
export const addProductInfo = (params: API.FinancingProduct.Item) => {
  return util.httpPost("/product", params, true, baseURL);
};
// 编辑融资产品
export const editProductInfo = (params: any) => {
  return util.httpPut("/product", params, true, baseURL);
};
// 融资产品详情
export const getProductInfo = (params: any) => {
  return util.httpGet(`/product/${params.id}`, "", true, baseURL, false);
};
// 获取省市区  组件地址
export const getCitys = () => {
  return util.httpGet("/pub-data/city/tree?maxDeep=3", {}, true, Basics_URL);
};

// 搜索
export const seatchCity = (code:any) => {
  return util.httpGet(`/pub-data/city`,{}, true, Basics_URL)
}
