export function convertData(
  orderDetail?: API.Schema.OrderDetail,
  supplyContracts?: API.Schema.SupplyContractItem[],
  entities?: {seller?: API.Entity.Info,buyer?: API.Entity.Info}
) {
  const localData: Partial<API.Order.Info> = {
    ...(orderDetail
      ? {
          id: orderDetail?.id.toString(),
          paymentStatus: orderDetail?.paymentStatus,
          subChannel: orderDetail.subChannel,
          supplier:entities?.seller? {
            name: orderDetail?.sellerName,
            type: entities.seller.type,
            taxNo: entities?.seller.taxNo, // 根据实际情况填充
          }:undefined,
          buyer: entities?.buyer?{
            name: orderDetail?.buyerName,
            type: entities.buyer.type,
            taxNo: entities?.buyer.taxNo, // 根据实际情况填充
          }:undefined,
          merchandises: [
            {
              unitOfMeasure:
                orderDetail?.unitOfMeasure?.toString() as API.unitOfMeasure,
              name: orderDetail.platCatName,
              quantity: orderDetail?.quantity?.toString(),
              unitPrice: orderDetail?.unitPrice,
              totalPrice: orderDetail?.beforeVatAmt,
              vatAmount: orderDetail?.vatAmt,
              totalAmount: orderDetail?.afterVatAmt,
            },
            // 可根据需要添加更多 merchandise 转换
          ],
          differentialAmount: orderDetail.inputAward,
          contract: {
            supplier: orderDetail.sellSigner?{
              idCard: orderDetail.sellSigner.idCard,
              signer: orderDetail.sellSigner.name,
              phone: orderDetail.sellSigner.phone,
              signStatus: ['SELLER_COMPLETED','ALL_COMPLETED'].includes(orderDetail.contractStatus),
            }:undefined,
            buyer: orderDetail.buySigner?{
              idCard: orderDetail.buySigner.idCard,
              signer: orderDetail.buySigner.name,
              phone: orderDetail.buySigner.phone,
              signStatus: ['BUYER_COMPLETED','ALL_COMPLETED'].includes(orderDetail.contractStatus),
            }:undefined,
            signStatus: orderDetail?.contractStatus,
            contractNo: orderDetail?.contractNo,
            generateTime: orderDetail.contractCreateDate,
          },
          amounts: {
            totalAmount: orderDetail?.afterVatAmt,
            paidAmount: orderDetail?.sellerIncomeAmt,
            unpaidAmount:
              orderDetail?.afterVatAmt - orderDetail?.sellerIncomeAmt,
            taxAmount: orderDetail?.vatAmt,
            paidTaxAmount: orderDetail?.paidVatAmt,
            unpaidTaxAmount: orderDetail?.vatAmt - orderDetail?.paidVatAmt,
            grossAmount: 0,
            paidGrossAmount: 0,
            unpaidGrossAmount: 0,
            deductAmount: 0, // 根据实际情况填充
          },
          createTime: orderDetail?.createdDate,
        }
      : {}),
    payments: [],
    logistics: [],
    supplyContracts: supplyContracts,
  };

  return localData;
}
