import React, { useEffect, useState, useRef } from "react";
import {
    Button,
    Input,
    Table,
    Modal,
    Form,
    InputNumber,
    Checkbox,
    Tag,
    message
} from "antd";
import styles from "./index.module.scss";
import BreadCrumbBar from "@src/components/BreadCrumbBar";
import { CaretRightOutlined, CaretDownOutlined } from "@ant-design/icons";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import { getCategoryTree, addCategoryTree, deleteCategory, editCategory } from "../promises";
const breads = [
    {
        label: "交易中心",
        path: "",
    },
    {
        label: "平台分类",
        path: "",
    },
];
const { Search } = Input;
interface DELETE {
    open: boolean;
    id: string;
}
interface ADD extends DELETE {
    type: string,
    name?: string;
    rootId?: string | number;
    depth?: string | number;
    parentId?: string
}

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const checkOptions = [
    {
        label: "交易品类",
        value: "forTrade",
    },
    {
        label: "价格指数",
        value: "forPriceIndex",
    },
];
const Classification = () => {
    const [loading, setLoading] = useState(false);
    const [totalList, setTotalList] = useState([])
    const [pageOptions, setPageOptions] = useState({
        pageSize: 10,
        current: 1,
        total: 0,
    });
    // 删除参数
    const [openParams, setOpenParams] = useState<DELETE>({
        open: false,
        id: "",
    });
    const [addParams, setAddParams] = useState<ADD>({
        type: 'add',
        open: false,
        id: "",
        name: "",
        rootId: "",
        depth: 1,
        parentId:''
    });
    const [form] = Form.useForm();
    const [list, setList] = useState([]);
    useEffect(() => {
        getLists();
    }, []);
    const getLists = async (obj?: any) => {
        setLoading(true);
        const res = await getCategoryTree();
        setTotalList(res?.data)
        
        setLoading(false);
        if (obj?.current && obj?.size) {
            const startIndex = (obj?.current - 1) * obj?.size
            const endIndex = obj.current * obj.size
            setList(res?.data.slice(startIndex, endIndex))
            setPageOptions({
                ...pageOptions,
                total: res?.data?.length,
                current: obj?.current,
                pageSize: obj?.size,
            })
        } else {
            const startIndex = (pageOptions.current - 1) * pageOptions.pageSize
            const endIndex = pageOptions.current * pageOptions.pageSize
            setList(res?.data.slice(startIndex,endIndex))
            setPageOptions({
                ...pageOptions,
                total: res?.data?.length
            }) 
        }
        
    };
    // const onSearch = (value: string) => {
    //     const params = {
    //         keyword: value,
    //     };
    //     getLists(params);
    // };
    const handlePage = (object: any) => {
        const startIndex = (object?.current - 1) * object?.pageSize
        const endIndex = object.current * object.pageSize
        setList(totalList.slice(startIndex, endIndex))
        setPageOptions({
            ...pageOptions,
            current: object.current,
            pageSize: object.pageSize,
        });
    };
    // 删除
    const handleDelete = async () => {
        // 删除 openParams
        const res:any = await deleteCategory(openParams?.id)
        if (res?.code === 200) {
            message.success('操作成功')
            const len = Number(totalList.length)
            const size = Number(pageOptions.pageSize)
            const num = len % size
            let lastNum = len / size 
            // const lastNum = parseInt(len/size)
            if (num < 2 && Number(pageOptions.current) === Math.ceil(lastNum)) {
                getLists({
                    size: pageOptions.pageSize,
                    current: pageOptions.current - 1
                })
            } else {
                getLists({
                    size: pageOptions.pageSize,
                    current: pageOptions.current
                })
            }
            setOpenParams({
                open: false,
                id: "",
            });

        }
    };
    const columns = [
        {
            title: "品类名称",
            dataIndex: "name",
            key: "name",
            width: '30%'
        },
        {
            title: "品类标识",
            // dataIndex: "age",
            // key: "age",
            render: (record: any) => {
                return (
                    <>
                        {record?.forTrade && <Tag color="blue">交易品类</Tag>}
                        {record?.forPriceIndex && (
                            <Tag color="volcano">价格指数</Tag>
                        )}
                    </>
                );
            },
        },
        {
            title: "排序",
            dataIndex: "sort",
            key: "sort",
        },
        {
            title: "操作",
            render: (record: any) => {
                return (
                    <div>
                        {
                            record?.depth < 5 && <Button type="link" onClick={() => {
                                setAddParams({
                                    ...addParams,
                                    open: true,
                                    rootId: record?.rootId,
                                    depth: record?.depth + 1,
                                    parentId: record?.id,
                                    name: record?.name,
                                    type: 'add'
                                })
                        }}>添加子品类</Button>
                        }
                        <Button type="link" onClick={() => {
                            console.log(record, 'edit')

                            let list = []
                            if (record?.forPriceIndex) {
                                list.push('forPriceIndex')
                            }
                             if (record?.forTrade) {
                                list.push('forTrade')
                            }
                            
                            const params = {
                                name: record?.name,
                                sort: record?.sort,
                                checkList: list
                            }
                            form.setFieldsValue(params)
                            setAddParams({
                                ...addParams,
                                open: true,
                                rootId: record?.rootId,
                                depth: record?.depth,
                                parentId: record?.parentId,
                                name: record?.name,
                                type: 'edit',
                                id: record?.id
                            })
                        }}>编辑</Button>
                        {!record.children && (
                            <Button
                                type="link"
                                onClick={() => {
                                    setOpenParams({
                                        open: true,
                                        id: record?.id,
                                    });
                                }}
                            >
                                删除
                            </Button>
                        )}
                    </div>
                );
            },
        },
    ];
    const expandable = {
        rowExpandable: (e: any) => {
            return true;
        },
        onExpand: (record: boolean, e: any) => {
            console.log(record, e, "record, e");
        },
        expandIcon: ({
            expanded,
            onExpand,
            record,
            expandable,
        }: {
            expanded: boolean;
            onExpand: (record: any, e: any) => void;
            record: boolean;
            expandable: any;
        }) => {
            return expandable ? (
                expanded ? (
                    <CaretDownOutlined onClick={(e) => onExpand(record, e)} />
                ) : (
                    <CaretRightOutlined onClick={(e) => onExpand(record, e)} />
                )
            ) : null;
        },
    };
    const handleOk = async () => {
        const res = await form.validateFields()
        let obj = {};
        if (res.checkList) {
            res.checkList.forEach((item: any) => {
                obj[item] = true;
            });
        }
        if (addParams.type === 'add') {
            // 当前是根节点新增的时候
            let params = {
                forPriceIndex: false,
                forTrade:false,
                sort: res.sort,
                ...obj,
                name: res.name,
                depth: addParams.depth,
                rootId: addParams.rootId,
                parentId: addParams?.parentId
            };
            if (addParams.rootId === '') {
                delete params.rootId
                delete params.parentId
            }
            const response:any = await addCategoryTree(params)
            if (response?.code === 200) {
                setAddParams({
                    name: "",
                    open: false,
                    id: "",
                    type:'add'
                });
                form.resetFields()
                getLists({
                    size: pageOptions.pageSize,
                    current: pageOptions.current
                })
            }
        } else {
            // editCategory
            let params = {
                forPriceIndex: false,
                forTrade:false,
                sort: res.sort,
                ...obj,
                name: res.name,
                depth: addParams.depth,
                rootId: addParams.rootId,
                parentId: addParams?.parentId
            };
            const response:any = await editCategory(addParams.id, params)
            if (response?.code === 200) {
                setAddParams({
                    name: "",
                    open: false,
                    id: "",
                    type:'add'
                });
                form.resetFields()
                getLists({
                    size: pageOptions.pageSize,
                    current: pageOptions.current
                })
            }
        }

    }
    return (
        <div className={styles.procure_conttainer}>
            <BreadCrumbBar breads={breads}></BreadCrumbBar>
            <div className={styles.procure_list}>
                <div className={styles.procure_list_title}>
                    <span>平台品类</span>
                    <div>
                        {/* <Search
                            placeholder="输入名称搜索"
                            onSearch={onSearch}
                            style={{ width: 200, marginRight: "20px" }}
                        /> */}
                        <Button
                            type="primary"
                            onClick={() => {
                                setAddParams({
                                    open: true,
                                    name: "",
                                    id: "",
                                    rootId: "",
                                    depth: 1,
                                    type: 'add'
                                });
                            }}
                        >
                            添加一级品类
                        </Button>
                    </div>
                </div>
                <Table
                    rowKey={(record: any) => record.id}
                    loading={loading}
                    size="small"
                    columns={columns}
                    dataSource={list}
                    pagination={{
                        current: pageOptions.current,
                        pageSize: pageOptions.pageSize,
                        total: pageOptions.total,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        showTotal(total, range) {
                            return `总共${total}条`
                        },
                    }}
                    onChange={handlePage}
                    expandable={expandable}
                />
            </div>
            <Modal
                title="删除"
                open={openParams.open}
                onCancel={() => {
                    setOpenParams({
                        open: false,
                        id: "",
                    });
                }}
                okText="确认删除"
                cancelText="取消"
                onOk={handleDelete}
            >
                <p>确定删除品类吗？删除将无法回复</p>
            </Modal>
            {/* 新增 */}
            <Modal
                title={addParams.type === 'add' ? "新增品类" : "编辑品类"}
                open={addParams.open}
                // open={true}
                onCancel={() => {
                    setAddParams({
                        name: "",
                        open: false,
                        id: "",
                        depth: 0,
                        type:'add'
                    });
                    form.resetFields()
                }}
                onOk={handleOk}
            >
                <Form {...layout} form={form}>
                    {
                        addParams.type === 'add' && <Form.Item label="上级品类">
                        <span>{addParams.name || "无"}</span>
                    </Form.Item>
                    }
                    
                    <Form.Item
                        label="品类名称"
                        name="name"
                        rules={[{ required: true, message: "请输入品类名称" }]}
                    >
                        <Input
                            placeholder="请输入品类名称"
                            showCount
                            maxLength={15}
                        ></Input>
                    </Form.Item>
                    <Form.Item
                        label="排序"
                        name="sort"
                        rules={[
                            { required: true, message: "请输入排序" },
                            {
                                pattern: /^[0-9]*[1-9][0-9]*$/,
                                message: "只能输入整数字的排序",
                            },
                        ]}
                    >
                        <InputNumber
                            placeholder="数字越小排序越靠前，最小为1"
                            min={1}
                            style={{ width: "100%" }}
                        ></InputNumber>
                    </Form.Item>
                    <Form.Item label="品类标识"  name="checkList">
                        <Checkbox.Group options={checkOptions} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default Classification;
